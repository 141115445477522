//const isChrome = window.navigator.userAgent.indexOf('70.0.3538') !== -1
//const API_URL = 'http://127.0.0.1:' + (isChrome ? '9000' : '8000') + '/api/';
const API_URL = process.env.PUBLIC_URL + '/backend/public/api/';
export const MODE_ADMIN = false;
export const API_LIST = 'list';
export const API_HIVES = API_URL + 'hives';
export const API_GROUPS = API_URL + 'groups';
export const API_STATS = API_URL + 'stats';
export const API_STATIONS = API_URL + 'stations';
export const API_CONTROLS = API_URL + 'controls';
export const API_CONFIGS = API_URL + 'configs';
export const API_CONFIG_HOURS = API_URL + 'config-hours';
export const API_USERS = API_URL + 'users';
export const API_DICT_TASKS = API_URL + 'dict-tasks';
export const API_TASKS = API_URL + 'tasks';
export const API_LOGIN = API_URL + 'login';
export const API_LOGOUT = API_URL + 'logout';
export const API_RESET = API_URL + 'reset';

export const API_DR_GROUPS = API_GROUPS + '/' + API_LIST + '/0';
export const API_DR_STATIONS = API_STATIONS + '/' + API_LIST;
export const API_DR_DICT_CONTROLS = API_URL + 'dict-controls/' + API_LIST;
export const API_DR_DICT_CONTROLS_PARAM_TYPE = API_URL + 'dict-controls-param-type/' + API_LIST;
export const API_DR_DICT_PARAMS = API_URL + 'dict-params/' + API_LIST;

export const API_DR_DICT_TASKS = API_DICT_TASKS + '/' + API_LIST + '/0';
export const API_DR_CONFIG_HOURS = API_URL + 'config-hours/' + API_LIST;
export const API_HIVES_ALERT = API_HIVES + '/alert';
export const API_HIVES_UA_COUNT = API_HIVES + '/count/unassignment';

export const API_DR_CLIENTS = API_USERS + '/' + API_LIST + '/0';

export const LS_GROUP = 'group';
export const LS_EDIT_MODE = 'editMode';
export const LS_CHART_FILTER = 'chartFilter';
export const LS_TOKEN = 'passport';
export const LS_USER = 'user';
export const LS_DARK_MODE = 'darkMode';
export const LS_ROWS_PER_PAGE = 'rowsPerPage';