import React from 'react';
import MainTable from '../../components/MainTable';
import {Container} from '@material-ui/core';
import ConfigHourForm from './ConfigHourForm';
import {API_CONFIG_HOURS,API_LIST} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'read_time',displayName:'Godzina',numeric: false,search:true},
    // {id:'if_default',displayName:'Domyślna',numeric: true,search:false,checkbox:true},
]

const ConfigHourList = () => {
    return ( 
        <Container maxWidth={false}>
            <MainTable EditForm={ConfigHourForm} title="Godziny odczytu" ListFilter={null} apiUrl={API_CONFIG_HOURS +'/'+ API_LIST} columns={columns} sortBy="read_time" csvName="lista_godzin_odczytu.csv" tableMinWidth={100}/>
        </Container>
     );
}
 
export default ConfigHourList;

