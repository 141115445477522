import React, { useState, useEffect, useContext } from 'react';
import { Grid, FormHelperText, Typography  } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import MainTableSelectable from '../../components/MainTableSelectable' ;
import { FormTextField, FormSelect } from '../../components/FormControls';
import { API_CONTROLS, API_DR_DICT_CONTROLS, API_GROUPS, API_HIVES, API_LIST } from '../../utils/Consts';
import { requestError, destructApiWhere, showStationTitle,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';

const columns=[
  {id:'id',displayName:'',numeric: true,search:false},
  {id:'uid_name',displayName:'Nazwa',numeric: false,search:true}
]

const ControlForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    groupId: -1,
    dictControlId: 0,
    parameterValue: '',
    parameterDescription: 'Wartość porządana',
    parameterRequired: false,        
    parameterOnlyGroup: false,        
    parameterOnlyWeight: false,        
  });
  const [listGroups,setListGroups] = useState([]);
  const [listDictControls,setListDictControls] = useState([]);
  const [listDictControlsAll,setListDictControlsAll] = useState([]);
  const [listSelectedHives,setListSelectedHives]=useState([]);

  useEffect(() => {
      axios.all([axios.get(API_DR_DICT_CONTROLS,axiosConfig(globalState.token)),axios.get(API_GROUPS + '/' + API_LIST + '/2',axiosConfig(globalState.token))]).then(axios.spread((response1,response2) => {
        setListDictControls(response1.data);
        setListDictControlsAll(response1.data);
        setListGroups(response2.data);
        console.log(response1.data);
        console.log(response2.data);
        if (apiWhere!=='') {
            const groupId=destructApiWhere(apiWhere)[0];
            setValues({...values,groupId:groupId});
        }  else {
            setValues({...values,groupId:0});
        } 
      })).catch(error=> {
          setErrors({'error':requestError(error)});
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps       
  },[]);  


  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnChangeGroupId = e => {
    const groupId = e.target.value;
    const group=findGroup(listGroups, groupId);
    setListDictControls(group.if_read_only===0 ? listDictControlsAll : listDictControlsAll.filter(dictControl => dictControl.if_only_weight===1));
    setValues({...values,[e.target.name]:groupId,parameterDescription:group.station_param_desc,dictControlId: 0,parameterRequired:false,parameterOnlyGroup:false,parameterOnlyWeight:false});
  };  

  const handleOnChangeDictControlId = e => {
      let parameterRequired,parameterOnlyGroup,parameterOnlyWeight;
      const dictControlId=e.target.value;
      if (dictControlId>0) {
        const dictControl=listDictControls.find(dictControl => dictControl.id===dictControlId);
        parameterRequired = dictControl.if_parameter===1; 
        parameterOnlyGroup = dictControl.if_group===1; 
        parameterOnlyWeight = dictControl.if_only_weight===1; 
      } else {
        parameterRequired=false;
        parameterOnlyGroup=false;
        parameterOnlyWeight=false;
      }
      setValues({...values,[e.target.name]:dictControlId,parameterRequired:parameterRequired,parameterOnlyGroup:parameterOnlyGroup,parameterOnlyWeight:parameterOnlyWeight,parameterDescription:findGroup(listGroups, values.groupId).station_param_desc});
  };   

  const findGroup = (list, groupId) => {
    return list.find(group => group.id===groupId);
  }

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {   
      const obj={'dict_control_id':values.dictControlId,'group_id':values.groupId,'hives_id':listSelectedHives,'param_value':values.parameterValue};            
      const promise=axios.post(API_CONTROLS, obj,axiosConfig(globalState.token));
      promise.then(response => {
          const respObj = response.data;
          handleOnClose(id,respObj,true);
      })
      .catch(error=>{ 
          setErrors({'error':requestError(error)});
      });
    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.groupId===0) validateErrors['groupId']='Wybierz grupę';    
    if (values.dictControlId===0) validateErrors['dictControlId']='Wybierz sterowanie';    
    if (values.dictControlId>0 && values.parameterRequired && values.parameterValue.length<1) validateErrors['parameterValue']=values.parameterDescription;
    if (!values.parameterOnlyGroup && listSelectedHives.length===0) validateErrors['error']='Wybierz przynajmniej jeden ul.';
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }  
    
  const showStation=()=>{
      if (values.groupId===0) return null;
      const group=findGroup(listGroups, values.groupId);
      return showStationTitle({name:group.station_name,city:group.station_city,set_year:group.station_set_year});
  }

  const hivesListPrepare=data=>{
      return values.parameterOnlyWeight?data.filter(hive=>hive.has_weight===1):data;
  }

  const hivesOnSelect=data=>{
      setListSelectedHives(data);
  }

  return (
      <MainForm title="Sterowanie" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} errors={errors} circuralProgress={values.groupId===-1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <FormSelect required name="groupId" value={values.groupId} label="Grupa" onChange={handleOnChangeGroupId} list={listGroups} emptyRowText="Wybierz grupę" error={errors['groupId']}/>
              {values.groupId>0 && <FormHelperText>{showStation()}</FormHelperText > }
          </Grid>
          <Grid item xs={12} sm={values.parameterRequired?6:12}>
              <FormSelect required name="dictControlId" value={values.dictControlId} label="Sterowanie" onChange={handleOnChangeDictControlId} list={listDictControls} error={errors['dictControlId']}/>
          </Grid>          
          {
              values.parameterRequired &&
              <Grid item xs={12} sm={values.parameterRequired?6:12}>
                  <FormTextField name="parameterValue" type="number" value={values.parameterValue} onChange={handleOnChange}
                   minNumber={-99} maxNumber={99} label={values.parameterDescription} error={errors['parameterValue']}/>
              </Grid>
          }
          {          
              (values.dictControlId>0 && !values.parameterOnlyGroup) && 
              <Grid item xs={12}>
                  <Typography>Wybierz ule</Typography>
                  <MainTableSelectable apiUrl={API_HIVES + '/' +  API_LIST + '/' + values.groupId + '/0'} 
                  apiKey={values.parameterOnlyWeight} 
                  columns={columns} 
                  sortBy="uid_name" 
                  prepareList={hivesListPrepare}
                  selectCallback={hivesOnSelect}/>                    
              </Grid>                    
          }
        </Grid>              
      </MainForm>
  );
};

export default ControlForm;
