import React,{useState,useEffect, useContext} from 'react';
import axios from 'axios';
import { Card, CardContent, Box, makeStyles } from '@material-ui/core';
import { FormSelect } from '../../components/FormControls';
import {API_DR_GROUPS, API_DR_DICT_CONTROLS, LS_GROUP} from '../../utils/Consts';
import {setLocalStorage, getLocalStorage, axiosConfig} from '../../utils/Helpers';
import { AppContext } from '../../app/AppProvider';

const useStyles = makeStyles((theme) => ({
    boxContainer: {
        display:"flex",
        alignItems:"flex-start",
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        },                  
    },
    boxGroup: {
      width:'100%',
      [theme.breakpoints.up('sm')]: {
        width: 300
      },        
    },
    boxControl: {
        width: '100%',
        marginLeft: 0,
        marginTop: theme.spacing(3),        
        [theme.breakpoints.up('sm')]: {
          width: 300,
          marginLeft: theme.spacing(3), 
          marginTop: 0
        },        
      },    
  }));

const ControlFilter = ({handleApiWhere,visible}) => {
    const classes = useStyles();    
    const { globalState } = useContext(AppContext);    
    const [groupId,setGroupId] = useState(0);
    const [dictControlId,setDictControlId] = useState(0);
    const [listGroups,setListGroups] = useState({})
    const [listDictControls,setListDictControls] = useState({})
    const setApiWhere =(pGroupId,pDictControlId)=> handleApiWhere(pGroupId+'/'+pDictControlId);   

    useEffect(()=>{
        axios.all([axios.get(API_DR_GROUPS,axiosConfig(globalState.token)),axios.get(API_DR_DICT_CONTROLS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2) => {
            setListGroups(response1.data);
            setListDictControls(response2.data);
            if (response1.data.length===1) {
                setGroupId(response1.data[0].id);
                setApiWhere(response1.data[0].id,dictControlId);
            } else {
                const lsGroupID=getLocalStorage(LS_GROUP);
                if (lsGroupID!=='') {
                    setGroupId(lsGroupID);
                    setApiWhere(lsGroupID,dictControlId);
                }
            }
        })).catch(error=> {
            console.log(error.response);
        }); 
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    },[]);     
    
    const handleGroupId=(e)=>{
        setGroupId(e.target.value);
        setLocalStorage(LS_GROUP,e.target.value);
        setApiWhere(e.target.value,dictControlId);
    }

    const handleDictControlId=(e)=>{
        setDictControlId(e.target.value);
        setApiWhere(groupId,e.target.value);
    }    
    
    return ( 
        visible && 
        <Card>
            <CardContent>   
                <Box className={classes.boxContainer}> 
                    <Box className={classes.boxGroup}>
                        <FormSelect value={groupId} onChange={handleGroupId} label="Grupa" list={listGroups} emptyRowText="Wybierz grupę"/>
                    </Box>
                    <Box className={classes.boxControl}>
                        <FormSelect value={dictControlId} onChange={handleDictControlId} label="Sterowanie" list={listDictControls} emptyRowText="Wszystko"/>
                    </Box>
                </Box>
            </CardContent>
        </Card>  
    );
}
 
export default ControlFilter;