import React, { useState, useEffect, useContext } from 'react';
import { Grid, FormHelperText, Typography  } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import MainTableSelectable from '../../components/MainTableSelectable' ;
import { FormTextField, FormSelect } from '../../components/FormControls';
import { API_TASKS, API_DR_DICT_TASKS, API_DR_GROUPS, API_HIVES, API_LIST } from '../../utils/Consts';
import { requestError, destructApiWhere, showStationTitle,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import {DatePickerKeyboard} from '../../utils/DatePicker';
import { AppContext } from '../../app/AppProvider';

const columns=[
  {id:'id',displayName:'',numeric: true,search:false},
  {id:'uid_name',displayName:'Nazwa',numeric: false,search:true}
]

const TaskForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    groupId: -1,
    dictTaskId: 0,
    taskDate: null,
    description: '',
    taskDateDone: null,
    hiveId: 0
  });
  const [listGroups,setListGroups] = useState([]);
  const [listDictTasks,setListDictTasks] = useState([]);    
  const [listSelectedHives,setListSelectedHives]=useState([]);  
  const [listHives,setListHives] = useState([]);

  useEffect(() => {
    if (id>0) {
            axios.all([axios.get(API_TASKS+'/'+id,axiosConfig(globalState.token)),axios.get(API_DR_DICT_TASKS,axiosConfig(globalState.token)),axios.get(API_DR_GROUPS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2,response3) => {
              setListDictTasks(response2.data);
              setListGroups(response3.data);

              console.log(response1.data);
              setValues({
                groupId:response1.data.group_id,
                hiveId:response1.data.hive_id,
                dictTaskId:response1.data.dict_task_id,
                taskDate:response1.data.task_date,
                description:response1.data.description,
                taskDateDone:response1.data.task_date_done
              });              
          })).catch(error=> {
              setErrors({'error':requestError(error)});
          });       
    } else {
        axios.all([axios.get(API_DR_DICT_TASKS,axiosConfig(globalState.token)),axios.get(API_DR_GROUPS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2) => {
          setListDictTasks(response1.data);
          setListGroups(response2.data);
          if (apiWhere!=='') {
              const groupId=destructApiWhere(apiWhere)[0];
              setValues({...values,groupId:groupId});
          } else {
              setValues({...values,groupId:0});
          }  
        })).catch(error=> {
            setErrors({'error':requestError(error)});
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);  


  useEffect(() => {
    if(id>0) {
        axios.get(API_HIVES +'/'+ API_LIST +'/'+ values.groupId + '/-1',axiosConfig(globalState.token)).then(response => {
          console.log(response.data);
            setListHives(response.data);
        }).catch(error=>{ 
            setErrors({'error':requestError(error)});
        });       
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[values.groupId]);

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {   
      let obj=null;
      if (id>0) {
          obj={'dict_task_id':values.dictTaskId,'task_date':values.taskDate,'hive_id':values.hiveId,'hives_id':[],'description':values.description,'task_date_done':values.taskDateDone};            
      } else {
          obj={'dict_task_id':values.dictTaskId,'task_date':values.taskDate,'hives_id':listSelectedHives,'description':values.description};
      }
      console.log(obj);
      const promise=id>0?axios.put(API_TASKS+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_TASKS, obj,axiosConfig(globalState.token));
      promise.then(response => {
          const filterParams = destructApiWhere(apiWhere);
          const inFilter = (filterParams[0]===values.groupId) && (filterParams[1]>0?filterParams[1]===values.dictTaskId:true) && (filterParams[2]===(values.taskDateDone!==null?1:0));
          const respObj = response.data;
          handleOnClose(id,respObj,inFilter);
      })
      .catch(error=>{ 
          setErrors({'error':requestError(error)});
      });
    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.groupId===0) validateErrors['groupId']='Wybierz grupę';    
    if (values.dictTaskId===0) validateErrors['dictTaskId']='Wybierz zadanie';    
    if (values.taskDate==='') validateErrors['taskDate']='Wprowadź datę zadania';    
    if (id===0 && listSelectedHives.length===0) validateErrors['error']='Wybierz przynajmniej jeden ul';
    if (id>0 && values.hiveId===0) validateErrors['hiveId']='Wybierz ul';    
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }  
    
  const showStation=()=>{
      if (values.groupId===0) return null;
      const group=listGroups.find(group => group.id===values.groupId);
      return showStationTitle({name:group.station_name,city:group.station_city,set_year:group.station_set_year})
  }


  const hivesOnSelect=data=>{
      setListSelectedHives(data);
  }

  const handleOnDelete= () => {
    axios.delete(API_TASKS+'/'+id,axiosConfig(globalState.token)).then(response => {
      handleOnClose(id,{},false);
    }).catch(error=>{ 
        setErrors({'error':requestError(error)});
    });
  }    

  // circuralProgress={listGroups.length===0 || (id>0 && listHives.length===0)}
  return (
      <MainForm title="Zadanie" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} onDelete={handleOnDelete} errors={errors} circuralProgress={values.groupId===-1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
              <FormSelect required name="groupId" value={values.groupId} label="Grupa" onChange={handleOnChange} list={listGroups} emptyRowText="Wybierz grupę" error={errors['groupId']}/>
              {values.groupId>0 && <FormHelperText>{showStation()}</FormHelperText> }
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
              <FormSelect required name="dictTaskId" value={values.dictTaskId} label="Zadanie" onChange={handleOnChange} list={listDictTasks} error={errors['dictTaskId']}/>
          </Grid> 
          <Grid item xs={12} sm={6} md={4}>
                    <DatePickerKeyboard
                          name="taskDate"
                          label="Data zadania"
                          value={values.taskDate}
                          onChange={date=>setValues({...values,taskDate:date})}
                          onClose={()=>null}
                          roller={true}
                          />
          </Grid>          
          <Grid item xs={12} sm={id>0?8:12}>
              <FormTextField name="description" value={values.description} label="Dodatkowy opis" maxLength={500} onChange={handleOnChange}/>
          </Grid>                 
          {   
            id>0 &&
            <Grid item xs={12} sm={4}>
                      <DatePickerKeyboard
                            name="taskDateDone"
                            label="Data wykonania"
                            value={values.taskDateDone}
                            onChange={date=>setValues({...values,taskDateDone:date})}
                            onClose={()=>null}
                            roller={true}
                            />
            </Grid>              
          }
          {          
              (id===0 && values.groupId>0) && 
              <Grid item xs={12}>
                  <Typography>Wybierz ule</Typography>
                  <MainTableSelectable apiUrl={API_HIVES +'/'+ API_LIST +'/'+ values.groupId + '/0'} 
                  columns={columns} 
                  sortBy="uid_name" 
                  selectCallback={hivesOnSelect}/>                    
              </Grid>                    
          }
          {   
            id>0 &&
            <Grid item xs={12}>
                <FormSelect name="hiveId" value={values.hiveId} label="Ul" onChange={handleOnChange} list={listHives} field="uid_name" error={errors['hiveId']}/>
            </Grid>              
          }          
        </Grid>              
      </MainForm>
  );
};

export default TaskForm;
