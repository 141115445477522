import React, { useState, useContext } from 'react';
import { Avatar, Button, TextField, Link, Paper, Box, Grid, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import MainErrorMessage from '../../components/MainErrorMessage';
import MainMessage from '../../components/MainMessage';
import { requestError,setLocalStorage } from '../../utils/Helpers';
import { API_LOGIN, API_RESET, LS_TOKEN, LS_USER, LS_DARK_MODE, MODE_ADMIN } from '../../utils/Consts';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },
  image: {
    backgroundImage: MODE_ADMIN ? 'url("bees.jpg")' : 'url("carrier.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: theme.palette.type === 'light' ? theme.palette.primary.main : '#fff',
  }
}));

export default function LoginForm({resetMode=false}) {
  const classes = useStyles();
  const { globalState, globalDispatch } = useContext(AppContext);    
  const [error,setError]=useState(''); 
  const [redirectMessage,setRedirectMessage]=useState(''); 
  const [values, setValues] = useState({
    email: '',
    password: '',
  });            

  const handleOnChange = e => {
     setValues({...values,[e.target.name]:e.target.value});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const obj={'email':values.email,'password':values.password};
    const promise=axios.post(API_LOGIN, obj);
    promise.then(response => {
        const respObj = response.data;
        console.log(respObj);
        setLocalStorage(LS_TOKEN,respObj.token);
        setLocalStorage(LS_USER,respObj.user.name);
        setLocalStorage(LS_DARK_MODE,respObj.user.if_dark_mode);
        globalDispatch({type:'TK',data:respObj.token});           
        globalDispatch({type:'US',data:respObj.user.name});           
        globalDispatch({type:'DM',data:respObj.user.if_dark_mode});                
    })
    .catch(error=>{       
      setError(error.response?.status===401?'Niepoprawne logowanie':requestError(error));
    });
  } 

  const handleOnSubmitReset = e => {
    e.preventDefault();
    const promise=axios.post(API_RESET +'/'+ values.email, {});
    promise.then(response => {
      setRedirectMessage('Odbierz email i zaloguj się za pomocą tymczasowego hasła.~~/logowanie~~Zaloguj');
    })
    .catch(error=>{       
        setError(requestError(error));
    });
  }   

  return (    
    (!resetMode && globalState.token.length>20)?<Redirect to="/"/>:
      <Paper>
      <Grid container component="main" className={classes.root}>        
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>            
            <Typography component="h1" variant="h5">{resetMode?'Resetowanie hasła':(MODE_ADMIN ? 'Administracja' : 'Logowanie')}</Typography>
            {error && <Box mt={2} mb={0} style={{width:'100%'}}><MainErrorMessage errors={{'error':error}}/></Box>}            
            {redirectMessage!=='' && <Box mt={2} mb={0} style={{width:'100%'}}><MainMessage message={redirectMessage}/></Box>}
            <form className={classes.form} onSubmit={resetMode?handleOnSubmitReset:handleOnSubmit}>
                  <TextField
                      value={values.email}
                      onChange={handleOnChange}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                  />
                  {
                    !resetMode &&
                    <TextField
                        value={values.password}
                        onChange={handleOnChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Hasło"
                        type="password"
                        autoComplete="current-password"
                    />
                  }
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}>
                      {resetMode?'Wyślij':'Zaloguj'}
                  </Button>
                  <Grid container>
                      <Grid item xs>
                          {resetMode?<Link className={classes.link} href={process.env.PUBLIC_URL + "/logowanie"} variant="body1">Logowanie</Link>:<Link className={classes.link} href={process.env.PUBLIC_URL + "/reset"} variant="body1">Zapomniałeś hasła?</Link>}
                      </Grid>
                  </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      </Paper>
  );
}