import React,{ useReducer } from 'react';
import { ThemeProvider,createMuiTheme } from "@material-ui/core/styles";
// import purple from "@material-ui/core/colors/purple";
// import green from "@material-ui/core/colors/green";
import {getLocalStorage} from '../utils/Helpers';
import {LS_TOKEN,LS_USER,LS_DARK_MODE} from '../utils/Consts';

const reducer = (state, action) => {    
    switch(action.type) {
        case 'TK': return {...state,token:action.data}
        case 'US': return {...state,user:action.data}
        case 'DM': return {...state,darkMode:action.data}
        default: return state;
    }
}

export const AppContext =  React.createContext();
const AppProvider = ({children}) => {
    //taken from localStorage during Refresh (F5)
    const [globalState, globalDispatch] = useReducer(reducer, {token:getLocalStorage(LS_TOKEN),user:getLocalStorage(LS_USER),darkMode:parseInt(getLocalStorage(LS_DARK_MODE,0))});

    console.log(globalState);
    const memoizedTheme = React.useMemo(()=>{
        return createMuiTheme({
            palette: {
                type: globalState.darkMode===1?'dark':'light',
                primary: {
                  main: '#008B8B',
                },
                secondary: {
                  main: '#CCB582',
                }               
              },
            shadows:['none',
            '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
            '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)']
        })
      }, [globalState.darkMode]);
          

     console.log('-----');
     console.log(globalState);
    return (
        <AppContext.Provider value={{ globalState, globalDispatch }}>
            <ThemeProvider theme={memoizedTheme}> 
                {children}
            </ThemeProvider>
        </AppContext.Provider>
    );
}
export default AppProvider;
