import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField, FormCheckbox } from '../../components/FormControls';
import { API_USERS } from '../../utils/Consts';
import { requestError,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import {setLocalStorage} from '../../utils/Helpers';
import {LS_USER,LS_DARK_MODE,MODE_ADMIN} from '../../utils/Consts';
import { AppContext } from '../../app/AppProvider';

const UserForm = ({apiWhere,handleOnClose}) => {
  const { globalState,globalDispatch } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    id: 0,
    name: '',
    email: '',
    password: '',
    ifDarkMode: 0
  });

  useEffect(() => {
    axios.get(API_USERS+'/-1',axiosConfig(globalState.token)).then(response => {
        setValues({    
          id:response.data.id,        
          name:response.data.name,
          email:response.data.email,
          password:response.data.password,
          ifDarkMode:response.data.if_dark_mode
        });
    }).catch(error=>{ 
        setErrors({'error':requestError(error)});
    });  
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);  

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {           
       const obj={'name':values.name,'email':values.email,'password':values.password,'if_dark_mode':values.ifDarkMode};
       const promise=axios.put(API_USERS+'/'+values.id, obj, axiosConfig(globalState.token));
       promise.then(response => {
           setLocalStorage(LS_USER,values.name);
           setLocalStorage(LS_DARK_MODE,values.ifDarkMode);
           globalDispatch({type:'US',data:values.name});           
           globalDispatch({type:'DM',data:values.ifDarkMode});
           const respObj = response.data;
           handleOnClose(values.id,respObj,true);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       });
    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.name.length<3) validateErrors['name']='Użytkownik musi zawierać co najmniej 3 znaki.';
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }   


  return (
      <>
        { 
          values.id > 0 &&
          <MainForm title="Użytkownik" id={values.id} onSubmit={handleOnSubmit} onCancel={handleOnClose} errors={errors}>
            <Grid container spacing={3}>  
              <Grid item xs={12}>
                  <FormTextField name="name" value={values.name} label="Użytkownik" onChange={handleOnChange} required minLength={2} maxLength={50} error={errors['name']}/>
              </Grid>
              <Grid item xs={12}>
                  <FormTextField name="email" value={values.email} label="Email" onChange={handleOnChange} required maxLength={50} disabled={!MODE_ADMIN}/>              
              </Grid>
              <Grid item xs={12}>
                  <FormTextField name="password" type="password" value={values.password} label="Hasło" onChange={handleOnChange} minLength={8} maxLength={50}/>
              </Grid>      
              <Grid item xs={12}>
                  <FormCheckbox  name="ifDarkMode" value={values.ifDarkMode} label="Tryb ciemny" onChange={handleOnChange} />
              </Grid>                
            </Grid>              
          </MainForm>
        }
      </>
  );
};

export default UserForm;
