import React,{useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { makeStyles,Card,CardContent,Box,Grid } from '@material-ui/core';
import { FormSelect, FormSwitch } from '../../components/FormControls';
import {API_DR_GROUPS,API_DR_DICT_TASKS,LS_GROUP, LS_EDIT_MODE} from '../../utils/Consts';
import {setLocalStorage,getLocalStorage,axiosConfig} from '../../utils/Helpers';
import { AppContext } from '../../app/AppProvider';

const useStyles = makeStyles((theme) => ({
    boxSwitches: {
        paddingTop:'0.4rem',  
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0
        },         
    },         
    boxContainer: {
        display:"flex",
        alignItems:"flex-start",
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        },                  
    },
    boxGroup: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 300
      },        
    },
    boxTask: {
        width: '100%',
        marginLeft: 0,
        marginTop: theme.spacing(3),        
        [theme.breakpoints.up('sm')]: {
          width: 300,
          marginLeft: theme.spacing(3),
          marginTop: 0
        },        
      },    
}));  

const TaskFilter = ({handleApiWhere,visible=true}) => {
    const classes = useStyles();
    const { globalState } = useContext(AppContext);    
    const [groupId,setGroupId] = useState(0);
    const [dictTasklId,setDictTaskId] = useState(0);
    const [showArchives,setShowArchives] = useState(0);    
    const [editMode,setEditMode]=useState(parseInt(getLocalStorage(LS_EDIT_MODE,0)));    
    const [listGroups,setListGroups] = useState({})
    const [listDictTasks,setListDictTasks] = useState({})
    const setApiWhere =(pGroupId,pDictTaskId,pShowArchives,pEditMode)=> handleApiWhere(pGroupId+'/'+pDictTaskId+'/'+pShowArchives+'/'+pEditMode);   

    useEffect(()=>{
        axios.all([axios.get(API_DR_GROUPS,axiosConfig(globalState.token)),axios.get(API_DR_DICT_TASKS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2) => {
            setListGroups(response1.data);
            setListDictTasks(response2.data);
            if (response1.data.length===1) {
                setGroupId(response1.data[0].id);
                setApiWhere(response1.data[0].id,dictTasklId,showArchives,editMode);
            } else {
                const lsGroupID=getLocalStorage(LS_GROUP);
                if (lsGroupID!=='') {
                    setGroupId(lsGroupID);
                    setApiWhere(lsGroupID,dictTasklId,showArchives,editMode);
                }
            }
        })).catch(error=> {
            console.log(error.response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps                   
    },[]);     
    
    const handleGroupId=(e)=>{
        setGroupId(e.target.value);
        setLocalStorage(LS_GROUP,e.target.value);        
        setApiWhere(e.target.value,dictTasklId,showArchives,editMode);
    }

    const handleDictTaskId=(e)=>{
        setDictTaskId(e.target.value);
        setApiWhere(groupId,e.target.value,showArchives,editMode);
    } 
           
    const handleShowArchives=(e)=>{
        const val=e.target.checked?1:0;
        setShowArchives(val);
        setApiWhere(groupId,dictTasklId,val,editMode);
    }  
    
    const handleOnEditMode=(e)=>{
        const val=e.target.checked?1:0;
        setEditMode(val);
        setLocalStorage(LS_EDIT_MODE,val);
        setApiWhere(groupId,dictTasklId,showArchives,val);
    }     
    
    return ( 
        visible && 
        <Card>
            <CardContent>   
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}> 
                        <Box className={classes.boxContainer}> 
                            <Box className={classes.boxGroup}>
                                <FormSelect value={groupId} onChange={handleGroupId} label="Grupa" list={listGroups} emptyRowText="Wybierz grupę"/>
                            </Box>
                            <Box className={classes.boxTask}>
                                <FormSelect value={dictTasklId} onChange={handleDictTaskId} label="Zadanie" list={listDictTasks} emptyRowText="Wszystko"/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={classes.boxSwitches} display="flex" justifyContent="flex-end">
                            {
                                showArchives===0 &&
                                <Box>
                                    <FormSwitch value={editMode} onChange={handleOnEditMode} label={editMode?"Edycja":"Oznaczanie"} labelPlacement="start" tooltip="Tryb edycji lub oznaczania"/>
                                </Box>    
                            }
                            <Box ml={1}>
                                <FormSwitch value={showArchives} onChange={handleShowArchives} label="Archiwalne" labelPlacement="start"/>
                            </Box>                         
                        </Box>                         
                    </Grid>
                </Grid>
            </CardContent>
        </Card>  
    );
}
 
export default TaskFilter;