import { FormControl } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { pl } from 'date-fns/locale';
import {convertDate2String} from './Helpers';

export const DatePickerKeyboard = ({onChange,onClose,name,label,value,visible=true,open=null,showTodayButton=true,disableFuture=false,disablePast=false,required=false,clearable=true,roller=false}) => {
    const style=visible?null:{display:'none',width:'0.5rem'};
    return (
        <FormControl fullWidth style={style}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <KeyboardDatePicker 
                    required={required} 
                    name={name} 
                    label={label}                                        
                    inputVariant="outlined"     
                    InputAdornmentProps={{ position: "start" }}                    
                    format="yyyy-MM-dd"
                    showTodayButton={showTodayButton}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    clearable={clearable}   
                    {...(open!==null && {open:open})}
                    {...(roller && {views:["year","month","date"],okLabel:"",openTo:"year"})}
                    autoOk={true}                    
                    todayLabel="Dzisiaj"
                    cancelLabel="Anuluj"
                    clearLabel="Wyczyść"
                    value={value}
                    onChange={date=>onChange(convertDate2String(date))}
                    onClose={onClose}
                    />                                 
            </MuiPickersUtilsProvider>
        </FormControl> 
    );
}


export const TimePickerKeyboard = ({onChange,name,label,value,required=false,clearable=true}) => {
    return (
        <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <KeyboardTimePicker
                    required={required}
                    name={name} 
                    label={label}             
                    clearable={clearable} 
                    ampm={false}                            
                    inputVariant="outlined"     
                    InputAdornmentProps={{ position: "start" }}    
                    mask="__:__"
                    cancelLabel="Anuluj"
                    clearLabel="Wyczyść"                    
                    value={value}
                    onChange={date => onChange(date)}
                    />                                
            </MuiPickersUtilsProvider>
        </FormControl> 
    );
}