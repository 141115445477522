import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { AppBar, Toolbar, makeStyles, Badge, Box, Hidden, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpIcon from '@material-ui/icons/Help';
import MainErrorMessage from '../components/MainErrorMessage';
import {API_HIVES_ALERT, MODE_ADMIN} from '../utils/Consts';
import {isNetworkError,axiosConfig,requestError} from '../utils/Helpers';
import { AppContext } from '../app/AppProvider';

const useStyles = makeStyles((theme) => ({
    appbar: {
      backgroundColor:theme.palette.type==='dark'?theme.palette.background.paper:theme.palette.primary.main,
    },
    toolbar: {
      height: 64
    }
  }));

const AppTopBar = ({handleOnNavOpen}) => {
  const classes = useStyles();
  const [error,setError]=useState('');    
  const { globalState } = useContext(AppContext);      
  const [countAlert,setcountAlert] = useState(0);    

  useEffect(() => {
    if (!MODE_ADMIN) {
       tick();
      const timerID = setInterval(() => tick(),60000);
      return () => { clearInterval(timerID); };    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const tick =()=> {
    axios.get(API_HIVES_ALERT + '/0',axiosConfig(globalState.token)).then(response => {       
        setcountAlert(response.data[0].count_alert);
    }).catch(error=>{ 
        if (!isNetworkError(error))
          setError(requestError(error));
    });  
  }  

  return (
    <AppBar className={classes.appbar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <IconButton color="inherit" component={Link} to='/'>
            <DashboardIcon fontSize="large"/>
        </IconButton>

        <Box flexGrow={1} />

        {!MODE_ADMIN &&
          <>
            <IconButton color="inherit" component={Link} to="/ostrzezenie">
                {
                  error!==''?<MainErrorMessage errors={{'error':error}}/>:
                  countAlert>0?<Badge badgeContent={countAlert} color="error"><NotificationsIcon fontSize="large"/></Badge>:
                  <NotificationsIcon fontSize="large"/>
                }
            </IconButton>

            <IconButton color="inherit" component={Link} onClick={(event) => { event.preventDefault(); window.open(process.env.PUBLIC_URL + '/System_inteligentnego_ula_dokumentacja.pdf');}}>
              <HelpIcon fontSize="medium"/>
            </IconButton>
          </>
        }

        <Hidden lgUp>
          <IconButton  color="inherit" onClick={handleOnNavOpen}>
            <MenuIcon fontSize="large"/>
          </IconButton>
        </Hidden>

      </Toolbar>
    </AppBar>
  );
}
 
export default AppTopBar;