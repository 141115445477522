import React,{ useState, useContext } from 'react';
import MainTable from '../../components/MainTable';
import MainTableSelectable from '../../components/MainTableSelectable';
import MainHeader from '../../components/MainHeader';
import MainErrorMessage from '../../components/MainErrorMessage';
import {Container, Box, Card, CardContent, Button, makeStyles} from '@material-ui/core';
import TaskForm from './TaskForm';
import TaskFilter from './TaskFilter';
import {API_TASKS,API_LIST} from '../../utils/Consts';
import { destructApiWhere,requestError,axiosConfig } from '../../utils/Helpers';
import {DatePickerKeyboard} from '../../utils/DatePicker';
import AlertDialog from '../../utils/AlertDialog';
import ConfirmDialog from '../../utils/ConfirmDialog';
import axios from 'axios';
import moment from 'moment';
import { AppContext } from '../../app/AppProvider';

const useStyles = makeStyles((theme) => ({          
    boxContainer: {
        display:"flex",
        alignItems:"flex-start",
        flexWrap: 'wrap',
    },
    boxDate: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 300
        }, 
    },
    boxButtonL: {
        marginLeft: 0,
        marginTop: theme.spacing(1),
        // flexGrow: 1,
        [theme.breakpoints.up('sm')]: {            
           marginLeft: theme.spacing(3),
           marginTop: 0
        },        
    },  
    boxButtonR: {
        marginLeft: 'auto',
        marginTop: theme.spacing(1),          
        [theme.breakpoints.up('sm')]: {   
            marginTop: 0,
        },        
    },        
    button: {
        marginTop:10,
        width:120
    }    
}));  

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'hive_uid_name',displayName:'Ul',numeric: false,search:true}, 
    {id:'task_date',displayName:'Data zadania',numeric: false,search:false},
    {id:'dict_task_name',displayName:'Zadanie',numeric: false,search:true},
    {id:'station_name',displayName:'Stanowisko',numeric: false,search:true},
    // {id:'station_city',displayName:'Lokalizacja',numeric: false,search:true},
    // {id:'station_set_year',displayName:'Rok',numeric: true,search:true},
    {id:'task_date_done',displayName:'Data wykonania',numeric: false,search:false},
]

const TaskList = () => {
    const classes = useStyles();    
    const { globalState } = useContext(AppContext);    
    const [error,setError]=useState('');
    const [alertDlg,setAlertDlg] = useState('');
    const [confirmDone,setConfirmDone]=useState('');      
    const [forceReload,setForceReload]=useState(false);
    const [filter,setFilter] = useState({apiUrlWhere:'0/0/0',editMode:0,archive:0});
    const [id,setId]= useState(-1); 
    const [addNew,setAddNew] = useState(false);
    const [taskDateDone, setTaskDateDone] = useState(moment().format('YYYY-MM-DD'));
    const [listSelectedTasks,setListSelectedTasks]=useState([]);  

    const callbackID=id=> {
        setId(id);
    }

    const hivesOnSelect=data=>{
        setListSelectedTasks(data);
    }  
         
    const handleOnTaskDone=()=>{
        let message='';
        if (taskDateDone===null) {
            message= 'Wybierz datę. ';
        }
        if (listSelectedTasks.length===0) {
            message = message + 'Oznacz przynajmniej jeden ul.';
        }
        if (message!=='') {
            setAlertDlg(message+'#Wykonanie');
        } else {            
            setConfirmDone('Czy chcesz oznaczyć wybrane zadania jako wykonane?#Wykonanie');
        }
    }

    const taskDoneDB=()=>{
        const obj={'task_date_done':taskDateDone,'tasks_id':listSelectedTasks};
        console.log(obj);
        const promise=axios.put(API_TASKS+'/'+API_LIST+'/arch', obj,axiosConfig(globalState.token));
        promise.then(response => {
            setForceReload(!forceReload);
        })
        .catch(error=>{ 
            setError(requestError(error));
        });
    }

    const handleConfirmDone= ok => {
        setConfirmDone('');
        if (ok) {
            taskDoneDB();
        }
    }    

    const handleOnAlertClose=()=> {
        setAlertDlg('');
    }

    const handleApiWhere=whereFilter=> {        
        const arrWhere=destructApiWhere(whereFilter);
        const where = arrWhere[0] + '/' + arrWhere[1] + '/' + arrWhere[2];
        setFilter({apiUrlWhere:where,archive:arrWhere[2],editMode:arrWhere[3]})
    }

    const handleEditFormClose=(idEditForm=-1,respObj=undefined,inFilter=true)=>{
        setAddNew(false);
        setForceReload(!forceReload);
    }
   
    return ( 
        <Container maxWidth={false}>
            {confirmDone!=='' && <ConfirmDialog dialogText={confirmDone} onClose={handleConfirmDone}/>}            
            {alertDlg!=='' && <AlertDialog dialogText={alertDlg} onClose={handleOnAlertClose}/>}
            {error && <MainErrorMessage errors={{'error':error}}/>}
            <MainHeader title="Zadania" visible={id<0 && !addNew}/>
            <Box mb={3}>
                <TaskFilter handleApiWhere={handleApiWhere} visible={id<0 && !addNew}/>
            </Box>
            {
                addNew?<TaskForm id={0} handleOnClose={handleEditFormClose} apiWhere={filter.apiUrlWhere}/>:
                <>                
                    {
                        (id<0 && filter.archive===0 && filter.editMode===0) &&
                        <Box mb={3}>
                            <Card>
                                <CardContent>                
                                    <Box className={classes.boxContainer}>
                                        <Box className={classes.boxDate}>
                                            <DatePickerKeyboard
                                                name="taskDateDone"
                                                label="Data wykonania"
                                                value={taskDateDone}
                                                onChange={date=>setTaskDateDone(date)}
                                                onClose={()=>null}
                                                roller={true}
                                                />
                                        </Box>
                                        <Box className={classes.boxButtonL}>
                                            <Button className={classes.button} onClick={handleOnTaskDone} variant="contained" color="primary">Wykonano</Button>
                                        </Box>
                                        <Box className={classes.boxButtonR}>
                                            <Button className={classes.button} onClick={()=>setAddNew(true)} variant="contained" color="primary">Dodaj</Button>
                                        </Box>                                            
                                    </Box>
                                </CardContent>
                            </Card>          
                        </Box> 
                    }

                    {
                    (filter.editMode===1 || filter.archive===1)?
                    <MainTable EditForm={TaskForm} ListFilter={null} apiUrl={API_TASKS+'/'+API_LIST+'/'} apiUrlWhere={filter.apiUrlWhere} apiUrlWhereFromParent={true} callbackID={callbackID} columns={filter.archive===1 ? columns : columns.slice(0,5)} sortBy="task_date" sortDirection="desc" csvName="lista_zadan.csv"/>:
                    <MainTableSelectable apiUrl={API_TASKS +'/'+ API_LIST +'/'+ filter.apiUrlWhere} apiKey={forceReload} columns={filter.archive===1 ? columns : columns.slice(0,5)}  sortBy="task_date" sortDirection="desc" selectCallback={hivesOnSelect}/>                            
                    }
                </>
            }
        </Container>
    );
}
 
export default TaskList;

