import React, {useState} from 'react'
import { Box, Grid, Typography, Card, CardContent} from '@material-ui/core';
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import FadeSnackbar from '../../utils/FadeSnackbar';
// import { Link } from 'react-router-dom';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';

const useStyles = makeStyles((theme) => ({ 
    cardContent: {
        borderColor: '#fff',
        borderWidth: theme.palette.type==='dark'?'0.5px':'0',  
        padding:'0.5rem 0'
    },
    cardBox: {
        clear:'both',
        padding:'0 0.5rem'     
    },
    typoH6: {
        [theme.breakpoints.down('sm')]: {
            fontSize:'1rem',         
        }          
    },
    typoSubtitle1: {
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.875rem',         
        }          
    }    
}));

const ChartsLastPi = ({listPi}) => {
    const classes = useStyles();
    const theme = useTheme();  
    const [snackBarMessage,setSnackBarMessage]=useState('');    

    const onCardClick = (id, coordinates) => {
        if (id===4) {
            //navigator.clipboard.writeText(message);
            //setSnackBarMessage('GPS skopiowano do schowka.');
            window.open('https://www.google.com/maps/search/?api=1&query=' + coordinates);
        }
    }

    const handleOnCloseSnackBar=()=> {
        setSnackBarMessage('');
    }      
    
    return (
        <>
            {snackBarMessage && <FadeSnackbar onClose={handleOnCloseSnackBar} message={snackBarMessage} typeMessage="success" autoHideDuration={3000}/>}         
            {/* <Link to="route" target="_blank" onClick={(event) => {event.preventDefault(); window.open('');}}>Test</Link> */}
            <Grid container spacing={2}>       
                {listPi && listPi.map(pi =>  
                    <Grid key={pi.id} item>                            
                        <Card onClick={() => onCardClick(pi.id, pi.header)}>
                            <CardContent className={classes.cardContent} style={{minWidth:pi.id>1 && pi.id<4?'120px':'200px',
                            cursor:pi.id!==4?'default':'pointer',backgroundColor:pi.moving ? theme.palette.secondary.main:null}}>
                                <Box className={classes.cardBox}>
                                    <Typography variant="h6" component="h2" align="center" className={classes.typoH6}>{pi.header}</Typography>
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                        {
                                            pi.id === 4 && (pi.moving ? 
                                            <GpsNotFixedIcon aria-label="gps-not-fixed" fontSize="small" style={{marginRight:'0.5rem'}}/> :                                        
                                            <GpsFixedIcon aria-label="gps-fixed" fontSize="small" style={{marginRight:'0.5rem'}}/>) 
                                        }
                                        <Typography variant="subtitle1" align="center" className={classes.typoSubtitle1}>{pi.footer}</Typography>                                         
                                    </div>
                                </Box>
                            </CardContent>                           
                        </Card>
                    </Grid>)
                }
            </Grid>
        </>
    )
}

export default ChartsLastPi;