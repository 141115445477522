import React from 'react';
import { Typography, Box } from '@material-ui/core';
const MainHeader = ({title='',visible=true,IconTitle=null}) => {
    return (                
        (visible && title!=='') && 
        <Box mb={1}>
            <Typography variant="h5" gutterBottom display="inline">{title}</Typography>
            {IconTitle && <IconTitle style={{marginLeft:'1rem'}}/>}
        </Box>        
    ); 
};
export default MainHeader;