import React, { useEffect, useState, useContext } from 'react';
import { Box, CircularProgress} from '@material-ui/core';
import {requestError,clearLocalStorage,axiosConfig } from '../../utils/Helpers';
import { API_LOGOUT, LS_TOKEN, LS_USER } from '../../utils/Consts';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';
import { Redirect } from 'react-router-dom';
import MainErrorMessage from '../../components/MainErrorMessage';

export default function LogoutForm() {          
  const { globalState,globalDispatch } = useContext(AppContext);
  const [error,setError]=useState('');
  const [logout,setLogout]=useState(false);

  const logoutApp = () => {
    clearLocalStorage(LS_TOKEN);
    clearLocalStorage(LS_USER);
    globalDispatch({type:'TK',data:''});           
    globalDispatch({type:'US',data:''});   
    setLogout(true);  
  }

  useEffect(() => {
      const promise=axios.post(API_LOGOUT,{},axiosConfig(globalState.token));
      promise.then(response => {
          logoutApp();                     
      })
      .catch(error=>{      
        const status=error.response?error.response.status:-1; 
        status !== 401?setError(requestError(error)):logoutApp();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps       
  },[]);

  return logout?
    <Redirect to="/logowanie"/>:
    (error?<Box mt={2} mb={0} style={{width:'100%'}}>
              <MainErrorMessage errors={{'error':error}}/>
            </Box>:
            <CircularProgress style={{margin:'1rem'}}/>)
}