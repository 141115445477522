import React from 'react';
import { Box  } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import { clearLocalStorage } from '../utils/Helpers';
import { LS_TOKEN, LS_USER } from '../utils/Consts';

const MainErrorMessage = ({errors}) => {
    const errorMap=(error)=>{
        const array=error.split('\n');
        const lis=array.slice(1).map(function(item, key) {return (<li key={key}>{item}</li>)});
        return <div>{array[0]}{lis.length?<ul>{lis}</ul>:''}</div>;    
    }        

    const refreshPage = ()=>{
        window.location.reload();
     }    

    const count=Object.keys(errors).length;
    if (count===0) return null;
    let msg = 'Formularz zawiera ' + count + ' ' + (count===1?'błąd':(count<=4?'błędy':'błędów')) + '!';

    if (errors['error']!==undefined) {        
        if (errors['error'].search('status code 401')>0) {
            clearLocalStorage(LS_TOKEN);
            clearLocalStorage(LS_USER);
            refreshPage();
        } else {
            msg = errorMap(errors['error']);
        }
    }

    return <Box mb={1}><Alert severity="error">{msg}</Alert></Box>
}
export default MainErrorMessage;