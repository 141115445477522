import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField, FormCheckbox } from '../../components/FormControls';
import { API_GROUPS } from '../../utils/Consts';
import { requestError, destructApiWhere,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';

const GroupForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    name: '',
    uid: '',
    ifReadOnly: 0,
    ifArchive: 0,
  });

  useEffect(() => {
    if (id>0) {
        axios.get(API_GROUPS+'/'+id,axiosConfig(globalState.token)).then(response => {
            setValues({
              name:response.data.name,
              uid:response.data.uid,
              ifReadOnly:response.data.if_read_only,
              ifArchive:response.data.if_archive,
            });
        }).catch(error=>{ 
            setErrors({'error':requestError(error)});
        });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);  

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {  
      const obj={'name':values.name,'uid':values.uid,'if_read_only':values.ifReadOnly,'if_archive':values.ifArchive};        
       const promise=id>0?axios.put(API_GROUPS+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_GROUPS, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const filterParams = destructApiWhere(apiWhere);
           const inFilter = (filterParams[0]===values.ifArchive);                
           const respObj = response.data;
           handleOnClose(id,respObj,inFilter);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       }); 

    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.name.length<3) validateErrors['name']='Nazwa musi zawierać co najmniej 3 znaki.';
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }       

  return (
      <MainForm title="Grupa" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} errors={errors}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <FormTextField name="name" value={values.name} label="Nazwa" onChange={handleOnChange} required minLength={3} maxLength={50} error={errors['name']}/>
          </Grid>           
          <Grid item xs={12}>
              <FormTextField name="uid" value={values.uid} label="Unikalny identyfikator grupy (mikrokomputera)" minLength={2} maxLength={10} required onChange={handleOnChange} disabled={true}/>
          </Grid>
          <Grid item xs={12}>
              <FormCheckbox name="ifReadOnly" value={values.ifReadOnly} label="Tylko czujniki, bez możliwości sterowania" onChange={handleOnChange} disabled={true}/>
          </Grid>            
          <Grid item xs={12}>
              <FormCheckbox name="ifArchive" value={values.ifArchive} label="Archiwalna" onChange={handleOnChange} disabled={true}/>
          </Grid>
        </Grid>              
      </MainForm>
  );
};

export default GroupForm;
