import React,{useState} from "react";
import { Button, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle } from "@material-ui/core";

const AlertDialog=({onClose,dialogText='Czy chcesz usunąć pozycję?#Usuwanie'})=> {
    const [open,setOpen] = useState(true);  
    const text = dialogText.split('#');
    const question = text[0];
    const title = text.length>1?text[1]:'';

    const handleOkClick = () => {
        setOpen(false);
        onClose();
    }

    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={open}
            onClose={handleOkClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
                {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
                <DialogContent>
                    <DialogContentText>{question}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOkClick} color="primary" autoFocus>OK</Button>
                </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;
