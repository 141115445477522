import React,{useState} from 'react';
import { Box, Grid,Typography,Card,CardContent,Checkbox,Button } from '@material-ui/core';
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import {FormCheckbox} from './FormControls';
import FadeSnackbar from '../utils/FadeSnackbar';


const useStyles = makeStyles((theme) => ({ 
    cardCheckbox: {
        float:'right',
        padding:0
    },
    cardContent: {
        borderColor: '#fff',
        borderWidth: theme.palette.type==='dark'?'0.5px':'0',
        cursor:'pointer',
        padding:0,
    },
    cardBox: {
        clear:'both',
        padding:'0.5rem 0.5rem 0 !important',        
    },
    typoH6: {
        [theme.breakpoints.down('sm')]: {
            fontSize:'1rem',         
        }          
    },
    typoSubtitle1: {
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.875rem',         
        }          
    }         
}));


const MainGrid = ({EditForm=undefined,list=null,edit=0,selectCallback=undefined,showActionButtons=0,listInitSelected=[],onEditFormMessage=''}) => {
    const classes = useStyles();     
    const [id,setId]= useState(-1);
    const [editMode,setEditMode] = useState(edit);
    const [selectAll,setSelectAll] = useState(0);    
    const [listSelected,setListSelected] = useState(listInitSelected);    
    const [snackBarMessage,setSnackBarMessage]=useState('');
    const theme = useTheme();

    const handleEditMode=e=> {
        setEditMode(e.target.checked?1:0);
        setListSelected([]);
    }

    const handleSelectAll=e=> {
        console.log('e.target.checked='+e.target.checked);
        setSelectAll(e.target.checked?1:0);
        const neWlistSelected=e.target.checked?list.map(row => row.id):[];
        setListSelected(neWlistSelected);
        if (selectCallback!==undefined) selectCallback(neWlistSelected);
    }

    const handleActionClick=()=>{
        alert('Akcja dla id: '+listSelected.join(','));
        setListSelected([]);
    }

    const handleCardClick=(id,alert)=> {
        if (editMode) {
            setId(id);
        } else {
            let neWlistSelected;
            const iIndex = listSelected.indexOf(id);
            if (iIndex>=0) {
                neWlistSelected=  [...listSelected];
                neWlistSelected.splice(iIndex,1);
            } else {
                neWlistSelected=  [...listSelected,id];
            }       
            setListSelected(neWlistSelected);            
            if (selectCallback!==undefined) selectCallback(neWlistSelected);
        } 
    }    

    const handleEditFormClose= (snackBarToast='') => {
        console.log('snackBarToast='+snackBarToast);
        setId(-1);
        if (snackBarToast!=='') setSnackBarMessage(snackBarToast);
    }

    const handleOnCloseSnackBar=()=> {
        setSnackBarMessage('');
    }    

    return ( 
        //id>=0?<EditForm id={id} handleOnClose={handleEditFormClose} gridMode={true}/>:
        id>=0?(onEditFormMessage!=='' ? handleEditFormClose(onEditFormMessage) : <EditForm id={id} handleOnClose={handleEditFormClose} gridMode={true}/>):
        <Box>
            {snackBarMessage && <FadeSnackbar onClose={handleOnCloseSnackBar} message={snackBarMessage} typeMessage="success" autoHideDuration={3000}/>} 
            {
                showActionButtons===0?(editMode===0 && <FormCheckbox label="Oznacz wszystkie" value={selectAll} handleOnChange={handleSelectAll}/>):(   
                <Box>                    
                    {editMode===0 && 
                        <>
                            <FormCheckbox label="Oznacz wszystkie" value={selectAll} onChange={handleSelectAll}/>
                            <Button variant="contained" color="primary" onClick={handleActionClick}>Dalej</Button>
                        </>
                    }     
                    <Box style={{float:'right'}}>
                        <FormCheckbox label="Tryb edycji" value={editMode} onChange={handleEditMode}/>
                    </Box>
                </Box> 
                )
            }   
            <Box>
                {
                    list.length>0 &&
                    <>
                        <Grid container spacing={2}>
                        {list.map(hive =>  
                            <Grid key={hive.id} item>                            
                                <Card onClick={() => handleCardClick(hive.id)}>
                                    <CardContent className={classes.cardContent} style={{backgroundColor:hive.alert?theme.palette.error.main:null}}>
                                        {editMode===0 && <Checkbox className={classes.cardCheckbox} checked={listSelected.indexOf(hive.id)>=0} onClick={() => handleCardClick(hive.id)}/>}
                                        <Box className={classes.cardBox}>
                                            <Typography variant="subtitle1" align="center" className={classes.typoSubtitle1}>{hive.header}</Typography>
                                            <Typography variant="subtitle1" align="center" className={classes.typoSubtitle1}>{hive.name}</Typography>
                                            <Typography variant="h6" component="h2" align="center" className={classes.typoH6}>{hive.body}</Typography>         
                                            <Typography variant="subtitle1" align="center" className={classes.typoSubtitle1}>{hive.footer}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                        </Grid>
                    </>
                }
            </Box>         
        </Box>
    );
}
 
export default MainGrid;