import React from 'react';
import '../css/App.css';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppProvider from './AppProvider';
import MainLayout from '../layouts/MainLayout';
import LoginForm from '../views/login/LoginForm';
import { HelmetTitle } from '../routes/RoutesSwitch';

const App = () => {     
  return (
    <AppProvider>    
        <CssBaseline />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
            <Route path="/logowanie">
                <HelmetTitle title="Logowanie"/> 
                <LoginForm resetMode={false}/>
            </Route>
            <Route path="/reset">
                <HelmetTitle title="Resetowanie hasła"/>
                <LoginForm resetMode={true}/>
            </Route>            
            <Route path="*">
                <MainLayout/> 
            </Route>
        </Switch>
        </BrowserRouter>
    </AppProvider>      
  );
}

export default App;
