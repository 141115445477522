import React from 'react';
import MainTable from '../../components/MainTable';
import {Container} from '@material-ui/core';
import StationForm from './StationForm';
import StationFilter from './StationFilter';
import {API_STATIONS,API_LIST} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'city',displayName:'Miejscowość',numeric: false,search:true},    
    {id:'name',displayName:'Nazwa',numeric: false,search:true},         
    {id:'group_name',displayName:'Grupa',numeric: false,search:true},
    {id:'set_year',displayName:'Rok',numeric: true,search:true},        
    {id:'if_mode_continue',displayName:'Odczyt ciągły',numeric: true,search:false,checkbox:true}        
]

const StationList = () => {
    return ( 
        <Container maxWidth={false}>
            <MainTable EditForm={StationForm} title="Stanowiska" ListFilter={StationFilter} apiUrl={API_STATIONS+'/'+API_LIST+'/'} apiUrlWhere="0" columns={columns} sortBy="set_year" sortDirection="desc" csvName="lista_stanowisk.csv"/>
        </Container>
     );
}
 
export default StationList;

