import React from 'react';
import MainTable from '../../components/MainTable';
import {Container} from '@material-ui/core';
import HiveForm from './HiveForm';
import HiveFormAdmin from './HiveFormAdmin';
import HiveFilter from './HiveFilter';
import HiveFilterAdmin from './HiveFilterAdmin';
import {API_HIVES,API_LIST,MODE_ADMIN} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'name',displayName:'Nazwa',numeric: false,search:true}, 
    {id:'uid',displayName:'Identyfikator',numeric: false,search:true},
    {id:'has_weight',displayName:'Waga',numeric: true,search:false,checkbox:true}
]

const columnsAdmin=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'client_name',displayName:'Klient',numeric: false,search:true},     
    {id:'uid',displayName:'Identyfikator',numeric: false,search:true},    
    {id:'has_weight',displayName:'Waga',numeric: true,search:false,checkbox:true}    
]

const HiveList = () => {
    return ( 
        <Container maxWidth={false}>
            <MainTable EditForm={MODE_ADMIN ? HiveFormAdmin : HiveForm} title="Ule" ListFilter={MODE_ADMIN ? HiveFilterAdmin : HiveFilter} apiUrl={API_HIVES+'/'+API_LIST+'/'} 
            apiUrlWhere={MODE_ADMIN ? "0" : "0/0"} columns={MODE_ADMIN ? columnsAdmin : columns} sortBy={MODE_ADMIN ? "client_name" : "name"} 
            csvName="lista_uli.csv" tableMinWidth={300} readOnly={!MODE_ADMIN}/>
        </Container>
     );
}
 
export default HiveList;

