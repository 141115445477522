import React from 'react';
import { Box  } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const MainMessage = ({message,severity="success"}) => {
    const arr = message.split('~~');
    const messageShow = arr[0];
    const link = arr.length>1?<a style={{marginLeft:'0.4rem',color:'rgb(30, 70, 32);'}} href={arr[1]}>{arr[2]}</a>:null;    
    return <Box mb={1}><Alert severity={severity}>{messageShow}{link}</Alert></Box>
}
export default MainMessage;