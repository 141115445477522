import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { API_CONFIG_HOURS } from '../../utils/Consts';
import { requestError,convertString2Time,convertTime2String,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import {TimePickerKeyboard} from '../../utils/DatePicker';
import { AppContext } from '../../app/AppProvider';

const ConfigHourForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);    
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    readTime: null,
    ifDefault: 0,
  });

  useEffect(() => {
    if (id>0) {
        axios.get(API_CONFIG_HOURS+'/'+id,axiosConfig(globalState.token)).then(response => {
            setValues({
              readTime:convertString2Time(response.data.read_time),
              ifDefault:response.data.if_default,
            });
        }).catch(error=>{ 
            setErrors({'error':requestError(error)});
        });  
    }
  },[id, globalState.token]);  

  // const handleOnChange = e => {
  //   const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
  //   setValues({...values,[e.target.name]:val});
  // };

  const handleOnChangeTime=time=>{
    setValues({...values,readTime:time});
  }  

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {           
       const obj={'read_time':convertTime2String(values.readTime)};            
       console.log(obj);
       const promise=id>0?axios.put(API_CONFIG_HOURS+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_CONFIG_HOURS, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const respObj = response.data;
           handleOnClose(id,respObj,true);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       }); 

    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.readTime===null) validateErrors['read_time']='Wypełnij godzinę odczytu.';
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }   

  const handleOnDelete= () => {
    axios.delete(API_CONFIG_HOURS+'/'+id,axiosConfig(globalState.token)).then(response => {
      handleOnClose(id,{},false);
    }).catch(error=>{ 
        setErrors({'error':requestError(error)});
    });
  }     


  return (
      <MainForm title="Godzina odczytu" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} onDelete={handleOnDelete} errors={errors}>
        <Grid container spacing={3}>
        <Grid item xs={12}>
              <TimePickerKeyboard
                            required
                            name="readTime"
                            label="Godzina"
                            value={values.readTime?values.readTime:null}
                            onChange={handleOnChangeTime}
                            />            
          </Grid>           
          {/* <Grid item xs={12}>
              <FormCheckbox name="ifDefault" value={values.ifDefault} label="Domyślna" onChange={handleOnChange} />
          </Grid> */}
        </Grid>              
      </MainForm>
  );
};

export default ConfigHourForm;
