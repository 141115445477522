import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Button, ListItem, makeStyles, Typography, FormHelperText  } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection:"column",
    alignItems:"flex-start"
  },
  button: {
    color: theme.palette.text.secondary,
    // fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {    
    color: theme.palette.type==='dark'?'#fff':theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.type==='dark'?'#fff':theme.palette.primary.main
    }
  }
}));

const MainListItem = ({href,icon:Icon,title,helperText='',navLink=false}) => {
  const classes = useStyles();
  return (
    <ListItem  className={classes.item} disableGutters>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={navLink?NavLink:Link}
        exact
        to={href}>
            <Icon className={classes.icon} style={{ fontSize: 25 }}/>
            <Typography variant="button" className={classes.title}>{title}</Typography>            
      </Button>
      {helperText!=='' && <FormHelperText style={{margin:'0 0 15px 40px'}}>{helperText}</FormHelperText>}
    </ListItem>
  );
};

export default MainListItem;
