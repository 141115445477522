import React from 'react';
import MainTable from '../../components/MainTable';
import {Container} from '@material-ui/core';
import DictTaskFilter from './DictTaskFilter';
import DictTaskForm from './DictTaskForm';
import {API_DICT_TASKS,API_LIST} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'name',displayName:'Nazwa',numeric: false,search:true}
]

const DictTaskList = () => {
    return ( 
        <Container maxWidth={false}>
            <MainTable EditForm={DictTaskForm} title="Rodzaje zadań" ListFilter={DictTaskFilter} apiUrl={API_DICT_TASKS+'/'+API_LIST+'/'} apiUrlWhere="0" columns={columns} sortBy="name" csvName="lista_rodzajow_zadana.csv" tableMinWidth={100}/>
        </Container>
     );
}
 
export default DictTaskList;

