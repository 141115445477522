import React, { useState, useEffect, useContext } from 'react';
import {  ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { requestError,showStationTitle,setLocalStorage,getLocalStorage,axiosConfig,convertParamsToArray } from '../../utils/Helpers';
import {API_STATS,API_LIST,API_DR_STATIONS,API_DR_CONFIG_HOURS,LS_CHART_FILTER,API_DR_DICT_PARAMS} from '../../utils/Consts';
import axios from 'axios';
import { Box, ButtonGroup, Button, Typography,FormControl,  Select, MenuItem, Card, CardContent, Container, IconButton, FormHelperText  } from '@material-ui/core';
import MainErrorMessage from '../../components/MainErrorMessage';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {DatePickerKeyboard} from '../../utils/DatePicker';
import { FormSwitch } from '../../components/FormControls';
import MainHeader from '../../components/MainHeader';
import MainTable from '../../components/MainTable';
import MainGrid from '../../components/MainGrid';
import ControlFormOne from '../controls/ControlFormOne';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HeightIcon from '@material-ui/icons/Height';
import { AppContext } from '../../app/AppProvider';
import ChartsLastPi from './ChartsLastPi';
import AlertDialog from '../../utils/AlertDialog';

export const useStyles = makeStyles((theme) => ({ 
    boxGroup: {
        flexDirection:'column',
        display:'inline-flex'
    },
    buttonGroup: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),  
        boxShadow:'none',
        border:0,
        [theme.breakpoints.down('sm')]: {
            display:'block',         
        },         
    }
}));

const columnsHistoryHives=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'dt',displayName:'Data godzina',numeric: false,search:true}, 
    {id:'hive_uid_name',displayName:'Ul',numeric: false,search:true},    
    {id:'p3',displayName:'Temp.pow.',numeric: false,search:false},    
    {id:'p1',displayName:'Temp.w kłębie',numeric: false,search:false},    
    {id:'p2',displayName:'Temp.wokół kłębu',numeric: false,search:false},    
    {id:'p4',displayName:'Wilgotność',numeric: false,search:false},
    {id:'p7',displayName:'Waga',numeric: false,search:false},
    {id:'error_message',displayName:'Błąd',numeric: false,search:false}
];

const columnsHistoryPi=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'dt',displayName:'Data godzina',numeric: false,search:true}, 
    // {id:'p1',displayName:'Bateria',numeric: false,search:false},        
    // {id:'p2',displayName:'Sygnał',numeric: false,search:false},    
    {id:'p3',displayName:'Temperatura',numeric: false,search:false},        
    {id:'p4',displayName:'Wilgotność',numeric: false,search:false},    
    {id:'error_message',displayName:'Błąd',numeric: false,search:false}
];


const Charts =()=> {
    const classes = useStyles();
    const theme = useTheme();   
    const defParameter = 11;
    const chartAxisStroke = theme.palette.type==='dark'?'#fff':'rgba(0, 0, 0, 0.54)';
    const { globalState } = useContext(AppContext);    
    const [error,setError]=useState('');  
    const [alertDlg,setAlertDlg] = useState('');         
    const [openDateDialog,setOpenDateDialog]=useState(0);     
    const [openStationDialog,setOpenStationDialog]=useState(false);         
    const [showHistory,setShowHistory] = useState(0);
    const [modeContinous,setModeContinous] = useState(false);
    const [groupReadOnly,setGroupReadOnly] = useState(0);
    const [forceReload,setForceReload]=useState(false);
    const [showToolbar,setShowToolbar]=useState(true);        

    const [filter,setFilter] = useState({stationId:0,stationArchive:false,stationName:'',readTime:0,parameter:defParameter,dateFrom:'0',dateTo:'0'});   
    //const [timeBack,setTimeBack]=useState(0);

    const [listStations,setListStations] = useState({})    
    const [listStationsArchive,setListStationsArchive] = useState({})    
    const [listConfigHours,setListConfigHours] = useState({})    
    const [listStats,setListStats]=useState([]);
    const [listHives,setListHives]=useState([]);
    const [listPi,setListPi]=useState({});    
    const [listDictParams,setListDictParams] = useState({});    
    const [arrDictParams,setArrDictParams] = useState([]);        
        

    const modfiyFilter=(pFilter)=> {
        const newFilter={...filter,...pFilter};
        setFilter(newFilter);
        setLocalStorage(LS_CHART_FILTER,JSON.stringify(newFilter));
        console.log(newFilter);
    }

    useEffect(() => { 
        // API: GET CURRENT STATIONS + CONFIG HOURS + DICT PARAMS
        axios.all([
        axios.get(API_DR_STATIONS+'/0',axiosConfig(globalState.token)),
        axios.get(API_DR_CONFIG_HOURS,axiosConfig(globalState.token)),
        axios.get(API_DR_DICT_PARAMS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2,response3) => {
            console.log('POBRANIE STATIONS + HOURS + DICT PARAMS');
            const listHours = response2.data;      
            const listStation = response1.data; 
            const listDictParams = response3.data;
            setListStations(listStation);                                 
            setListConfigHours(listHours);
            setListDictParams(listDictParams);
            setArrDictParams(convertParamsToArray(listDictParams))

            let lsStation;
            const lsFilter=getLocalStorage(LS_CHART_FILTER);
            if (lsFilter!=='') {
                // read from local storage
                const lsFilterObj=JSON.parse(lsFilter);

                // there is no parameter in list
                if (listDictParams.find(dictParam=>dictParam.id===lsFilterObj.parameter) === undefined)
                    lsFilterObj.parameter = defParameter;

                lsStation = listStation.find(station=>station.id===lsFilterObj.stationId);
                if (lsStation!==undefined) {
                    modfiyFilter(lsFilterObj);
                    setModeContinous(lsStation.if_mode_continue);
                    setGroupReadOnly(lsStation.if_read_only);
                } else {
                    modfiyFilter(lsFilterObj);
                    setGroupReadOnly(1);                    
                }                
            } else {
                // there is no local storage, first visit
                const def = listHours.find(row => row.if_default===1);
                const readTime = (def!==undefined?def.read_time:0);
                lsStation = listStation.find(station=>station.id>0);
                if (lsStation!==undefined) {
                    setModeContinous(lsStation.if_mode_continue);
                    setGroupReadOnly(lsStation.if_read_only);
                    modfiyFilter({stationId:lsStation.id,stationArchive:false,stationName:showStationTitle(lsStation),readTime:readTime});
                } else {
                    modfiyFilter({readTime:readTime});
                }                
            }
        })).catch(error=> {
            setListStations({});
            setListConfigHours({});
            setListDictParams({});
            setArrDictParams([]);
            setError(requestError(error));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps                   
    },[]);    

    useEffect(() => {
        // API: GET CHART STATISTICTS
        if (filter.stationId !== 0) {
            axios.get(API_STATS+'/'+API_LIST+'/'+getApiUrl(),axiosConfig(globalState.token)).then(response => {
                //console.log('POBRANIE LIST');
                //console.log(response.data.pi);
                setListStats(response.data.stats);
                const hivesMap = Object.entries(response.data.hives).map(([key, hive]) => ({'id':hive.id,'header':key,'name':hive.name,'body':hive.param + arrDictParams[filter.parameter].unit,'footer':hive.dt,'color':hive.color,'alert':hive.alert}));
                setListHives(hivesMap);              
                preparePiStats(response.data.pi);                
            }).catch(error=>{ 
                setListStats([]);
                setListHives([]);
                setListPi([]);
                setError(requestError(error));
            });            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    },[filter,forceReload]); 

    const preparePiStats = statsPi => {
        if (Object.keys(statsPi).length === 0 || !arrDictParams.length) {
            setListPi({});
            return;
        }

        const stat_dt = statsPi.stat_date !== undefined ? statsPi.stat_date + ' ' + statsPi.stat_time : 'Brak';
        const gps_dt = statsPi.gps_date !== undefined ? statsPi.gps_date + ' ' + statsPi.gps_time: 'Brak';
        const piList = [
            {'id':1,'header':stat_dt,'footer':'Ostatni odczyt'},
            {'id':2,'header':statsPi.p3 !== undefined ? statsPi.p3 + arrDictParams[3].unit : 'Brak','footer':arrDictParams[3].name},
            {'id':3,'header':statsPi.p4 !== undefined ? statsPi.p4 + arrDictParams[4].unit : 'Brak','footer':arrDictParams[4].name},
            {'id':4,'header':statsPi.latitude !== undefined ? statsPi.latitude + ', ' + statsPi.longitude : 'Brak','footer':(stat_dt !== gps_dt ?  gps_dt : '(pokaż)'), 'moving': statsPi.is_moving},            
        ];        
        setListPi(piList);
    }

    useEffect(() => {
        let timerID = null;
        modeContinous?timerID=setInterval(() => tick(),60000):clearInterval(timerID);;
        return () => { clearInterval(timerID); };    
    },[modeContinous]);
      
    const tick =()=> {
        //console.log('TIMER='+forceReload);
        setForceReload(oldForceReload=>!oldForceReload);
    }      

    
    const getApiUrl = (history=false) => {
        const paramType = (history ? ( filter.parameter>10 ? 0 : -1 ): filter.parameter); 
        return filter.stationId + '/' + paramType + '/' + filter.readTime + '/' + filter.dateFrom + '/' + filter.dateTo;
    }

    const handleDateClick = (type) => {
        setOpenDateDialog(type);
    }

    const handleDateChanged = (date) => {
        const dateF = date?date:"0";
        openDateDialog===1?modfiyFilter({dateFrom:dateF}):modfiyFilter({dateTo:dateF});
        setOpenDateDialog(0);
      
    }    

    const handleStationClick=id=> {
        if (id>0) {
            const readTime = onModeContinous(id,false);
            let station = listStations.find(row => row.id === id);
            modfiyFilter({stationId:id,stationArchive:false,stationName:showStationTitle(station),readTime:readTime});            
            setGroupReadOnly(station.if_read_only);
        } else {
          if (listStationsArchive.length) {
              setOpenStationDialog(true);
          } else {         
              // API: GET ARCHIVE STATIONS  
              axios.get(API_DR_STATIONS+'/1',axiosConfig(globalState.token)).then(response => {
                  console.log('POBRANIE STATIONS ARCHIVE');
                  console.log(response.data.length);
                  if (response.data.length>0) {
                    setListStationsArchive(response.data);
                    setOpenStationDialog(true);  
                  } else {
                    setAlertDlg('Brak stanowisk archiwalnych.');
                  }
              }).catch(error=>{
                  setListStationsArchive({});
                  setError(requestError(error));
              });           
          }
        }
    }

    const handleStationChangedArchive = id => {
        setOpenStationDialog(false);
        const readTime = onModeContinous(0,true);
        let station = listStationsArchive.find(row => row.id === id);        
        modfiyFilter({stationId:id,stationArchive:true,stationName:showStationTitle(station),readTime:readTime});        
        setGroupReadOnly(1);
    }

    const onModeContinous=(id,archive)=>{
        const newModeContinous=archive?false:listStations.find(station => station.id===id).if_mode_continue===1;
        setModeContinous(newModeContinous);
        return modeContinous && !newModeContinous && filter.readTime===-1?0:filter.readTime;
    }    

    const handleReadTimeClick=readTime=> {
        modfiyFilter({readTime:readTime});
    }

    const handleShowHistory = e => {      
        setShowHistory(e.target.checked?1:0); 
    }

    const handleOnAlertClose=()=> {
        setAlertDlg('');
    }    

    const toolbarTitle=()=>{
        let paramName = '';
        let readDate = '';
        let readTime = '';
        if (listDictParams.length) {
            const dictParamSel = listDictParams.filter(dp => filter.parameter===dp.id);
            if (dictParamSel.length) {
                paramName = dictParamSel[0].name;
            }
        }
        
        if ((filter.dateFrom!=='0') || (filter.dateTo!=='0')) {
            readDate = (filter.dateFrom!=='0'?'od '+filter.dateFrom+' ':'') + (filter.dateTo!=='0'?'do '+filter.dateTo:'');
        } else {
            readDate = 'wszystko'
        }

        switch (filter.readTime) {
            case -1:
                readTime = 'ostatnia';
                break;
            case 0:
                readTime = 'wszystkie';
                break;                
            default:
                readTime = filter.readTime; 
        }

        return <Box>
            <Box>
                <Typography variant="subtitle1" display="inline" >{paramName} , data: {readDate} , godz: {readTime}</Typography>
            </Box>
        </Box>
    }

    return (
        <Container maxWidth={false}>
            { error && <MainErrorMessage errors={{'error':error}}/> }
            {alertDlg!=='' && <AlertDialog dialogText={alertDlg} onClose={handleOnAlertClose}/>}            
            <MainHeader title={filter.stationName} visible={true} IconTitle={modeContinous?ScheduleIcon:null}/>
            {
                !error &&
                // TOOLBAR
                <Box mb={3}>
                    <Card >
                        <CardContent style={{paddingTop:'0.5rem',position:'relative',paddingBottom:'0.5rem'}}>
                            <Box style={{position:'absolute',top:0,right:0}}>
                                <IconButton color="inherit">
                                    <HeightIcon onClick={()=>setShowToolbar(!showToolbar)}/>
                                </IconButton>
                            </Box>
                            {!showToolbar?toolbarTitle():
                            <Box>
                                {   listStations.length>0 &&  
                                    <Box className={classes.boxGroup}>                                   
                                                                
                                            <ButtonGroup variant="contained" color="primary" size="medium" className={classes.buttonGroup}>                                    
                                                {listStations.map(station => <Button onClick={()=>handleStationClick(station.id)} key={station.id} className={filter.stationId===station.id?'btnc-active':'btnc-gray'}>{station.name}</Button>)}
                                                <Button className={filter.stationArchive?'btnc-active':'btnc-gray'} onClick={()=>handleStationClick(0)}>Archiwalne</Button>
                                            </ButtonGroup> 
                                                                                                            
                                        <FormControl style={{display:'none',width:'0.5rem'}}>
                                            <Select open={openStationDialog} value={filter.stationId} onChange={e=>handleStationChangedArchive(e.target.value)}>
                                                { listStationsArchive.length && listStationsArchive.map(row => <MenuItem key={row.id} value={row.id}>{showStationTitle(row)}</MenuItem>) }
                                            </Select>
                                        </FormControl> 
                                        <FormHelperText>Stanowiska</FormHelperText>
                                    </Box>
                                }                                                                 

                                { listConfigHours.length && 
                                    <Box className={classes.boxGroup}>   
                                        <ButtonGroup  variant="contained" color="primary" size="medium" className={classes.buttonGroup}>
                                            <Button onClick={()=>handleDateClick(1)} className={filter.dateFrom!=='0'?'btnc-active':'btnc-gray'}>{filter.dateFrom!=='0'?filter.dateFrom:'Od początku'}</Button>
                                            <Button onClick={()=>handleDateClick(2)} className={filter.dateTo!=='0'?'btnc-active':'btnc-gray'}>{filter.dateTo!=='0'?filter.dateTo:'Do końca'}</Button>
                                            {/* <Button>Cały dzień</Button> */}
                                        </ButtonGroup>
                                        <FormHelperText>Daty odczytów</FormHelperText>
                                        <DatePickerKeyboard
                                                value={openDateDialog===1?(filter.dateFrom!=='0'?filter.dateFrom:null):(filter.dateTo!=='0'?filter.dateTo:null)}
                                                onChange={handleDateChanged}
                                                onClose={()=>setOpenDateDialog(0)}
                                                disableFuture={true}
                                                open={openDateDialog>0}
                                                visible={false}/>
                                    </Box>
                                }
                        
                                { listConfigHours.length && 
                                    <Box className={classes.boxGroup}>   
                                        <ButtonGroup variant="contained" color="primary" size="medium" className={classes.buttonGroup}>                                                                    
                                            {listConfigHours.map(hour => <Button onClick={()=>handleReadTimeClick(hour.read_time)} key={hour.id} className={filter.readTime===hour.read_time?'btnc-active':'btnc-gray'}>{hour.read_time}</Button>)}
                                            {modeContinous && <Button className={filter.readTime === -1 ?'btnc-active':'btnc-gray'} onClick={()=>handleReadTimeClick(-1)}>Ost.godz.</Button>}
                                            <Button className={filter.readTime === 0?'btnc-active':'btnc-gray'} onClick={()=>handleReadTimeClick(0)}>Wszystkie</Button>
                                        </ButtonGroup>
                                        <FormHelperText>Godziny odczytów</FormHelperText>
                                    </Box>
                                }

                                { listDictParams.length && 
                                    <Box>
                                        <Box className={classes.boxGroup}>                                    
                                            <ButtonGroup  variant="contained" color="primary" size="medium" className={classes.buttonGroup}>
                                                { listDictParams.map(dp => dp.id<10 && <Button onClick={()=>modfiyFilter({parameter:dp.id})} key={dp.id} className={filter.parameter===dp.id?'btnc-active':'btnc-gray'}>{dp.name}</Button>) }
                                            </ButtonGroup>
                                            <FormHelperText>Parametry zewnętrzne</FormHelperText>
                                        </Box>
                                        <Box className={classes.boxGroup}>
                                            <ButtonGroup  variant="contained" color="primary" size="medium" className={classes.buttonGroup}>
                                                { listDictParams.map(dp => dp.id>10 && <Button onClick={()=>modfiyFilter({parameter:dp.id})} key={dp.id} className={filter.parameter===dp.id?'btnc-active':'btnc-gray'}>{dp.name}</Button>) }
                                            </ButtonGroup>
                                            <FormHelperText>Parametry uli</FormHelperText>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            }   

                        </CardContent>
                    </Card>
                </Box>
            }

            {/* PI */}
            {(!error && showHistory === 0 && listPi.length>0) &&
                <Box mb={3}>
                    <ChartsLastPi listPi={listPi}/>
                </Box>
            }  

            {/* HIVES */}
            {(!error && showHistory === 0 && listStats.length>0 && filter.parameter>10) &&
                <Box mb={3}>
                    <Card >
                        <CardContent>                                     
                            <MainGrid EditForm={ControlFormOne} list={listHives} edit={1} showActionButtons={0}/>
                        </CardContent>
                    </Card>
                </Box> 
            }  

            {/* CHARTS */}
            {(!error && showHistory === 0) &&
                <Box mb={3}>
                        <Card >
                            <CardContent>                                     
                                {
                                    listStats.length<1?<Typography align="center">Brak danych</Typography>:
                                    <Box style={{ width: '100%',height:'600px'}}>
                                        <ResponsiveContainer>
                                            <LineChart data={listStats} margin={{top: 5, right: 0, left: 0, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="dt" stroke={chartAxisStroke} dy={10} height={44}/>
                                                <YAxis stroke={chartAxisStroke} dx={-10}/>
                                                <Tooltip />
                                                <Legend iconType="square"/>
                                                {listHives.length && listHives.map(hive => <Line key={hive.id} type="linear" dataKey={hive.header} stroke={hive.color}/>)}
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Box>
                                }         
                            </CardContent>
                    </Card>
                </Box>
            }       

            {/* SWITCH + HISTORY TABLE */}
            {(!error && listStats.length>0) &&
                <Box className="tableNoFirstColumn">
                    <Box>
                        <FormSwitch name="historySwitch" label="Historia odczytów" value={showHistory} onChange={handleShowHistory} />
                    </Box>
                    { 
                        showHistory>0 &&
                        <MainTable EditForm={null} ListFilter={null} apiUrl={API_STATS+'/'+API_LIST+'/'} apiUrlWhere={getApiUrl(true)} apiUrlWhereFromParent={true} apiKey={forceReload} columns={filter.parameter>10?columnsHistoryHives:columnsHistoryPi} sortBy="dt" sortDirection="desc" csvName="lista_odczytow.csv" readOnly={true}/>
                    }
                </Box>
            }
        </Container>
    );
}


export default Charts;
