import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField, FormCheckbox } from '../../components/FormControls';
import { API_DICT_TASKS } from '../../utils/Consts';
import { requestError, destructApiWhere,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';

const DictTaskForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    name: '',
    ifArchive: 0,
  });

  useEffect(() => {
    if (id>0) {
        axios.get(API_DICT_TASKS+'/'+id,axiosConfig(globalState.token)).then(response => {
            setValues({
              name:response.data.name,
              ifArchive:response.data.if_archive,
            });
        }).catch(error=>{ 
            setErrors({'error':requestError(error)});
        });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);  

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {           
       const obj={'name':values.name,'if_archive':values.ifArchive};            
       const promise=id>0?axios.put(API_DICT_TASKS+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_DICT_TASKS, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const filterParams = destructApiWhere(apiWhere);
           const inFilter = (filterParams[0]===values.ifArchive);                
            const respObj = response.data;
           handleOnClose(id,respObj,inFilter);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       }); 

    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.name.length<3) validateErrors['name']='Nazwa musi zawierać co najmniej 3 znaki.';
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }   

  const handleOnDelete= () => {
    axios.delete(API_DICT_TASKS+'/'+id,axiosConfig(globalState.token)).then(response => {
      handleOnClose(id,{},false);
    }).catch(error=>{ 
        setErrors({'error':requestError(error)});
    });
  }     

  return (
      <MainForm title="Rodzaj zadania" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} onDelete={handleOnDelete} errors={errors}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <FormTextField name="name" value={values.name} label="Nazwa" onChange={handleOnChange} required minLength={3} maxLength={100} error={errors['name']}/>
          </Grid>            
          <Grid item xs={12}>
              <FormCheckbox name="ifArchive" value={values.ifArchive} label="Archiwalne" onChange={handleOnChange} />
          </Grid>
        </Grid>              
      </MainForm>
  );
};

export default DictTaskForm;
