import React from 'react';
import MainTable from '../../components/MainTable';
// import MainTableSelectable from '../../components/MainTableSelectable';
import {Container} from '@material-ui/core';
import GroupForm from './GroupForm';
import GroupFormAdmin from './GroupFormAdmin';
import GroupFilter from './GroupFilter';
import {API_GROUPS,API_LIST,MODE_ADMIN} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'name',displayName:'Nazwa',numeric: false,search:true},
    {id:'uid',displayName:'Identyfikator',numeric: false,search:true}     
]

const columnsAdmin=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'client_name',displayName:'Klient',numeric: false,search:true},     
    {id:'uid',displayName:'Identyfikator',numeric: false,search:true},    
]

const GroupList = () => {
    return ( 
        <Container maxWidth={false}>
            <MainTable EditForm={MODE_ADMIN ? GroupFormAdmin : GroupForm} title="Grupy" ListFilter={GroupFilter} 
            apiUrl={API_GROUPS+'/'+API_LIST+'/'} apiUrlWhere="0" columns={MODE_ADMIN ? columnsAdmin : columns} 
            sortBy={MODE_ADMIN ? "client_name" : "name"} csvName="lista_grup_uli.csv" tableMinWidth={300} readOnly={!MODE_ADMIN}/>
        </Container>
     );
}
 
export default GroupList;

