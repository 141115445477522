import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField } from '../../components/FormControls';
import { API_CONFIGS } from '../../utils/Consts';
import { requestError,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';

const ConfigForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    workMode: 0,
    workInterval: 0,
    tempInMax: '',
    tempOutMax: '',
    humidityMax: '',
    co2Max: '',
  });

  useEffect(() => {
    if (id>0) {
      axios.get(API_CONFIGS+'/'+id,axiosConfig(globalState.token)).then(response => {
          setValues({
            workInterval:response.data.work_interval,
            tempInMax:response.data.c_temp_in,
            tempOutMax:response.data.c_temp_out,
            humidityMax:response.data.c_humidity,
            co2Max:response.data.c_co2
          });
      }).catch(error=>{ 
          setErrors({'error':requestError(error)});
      });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);  

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {           
       const obj={'work_interval':values.workInterval,'c_temp_in':values.tempInMax,'c_temp_out':values.tempOutMax,'c_humidity':values.humidityMax,'c_co2':values.co2Max};          
       const promise=axios.put(API_CONFIGS+'/'+id, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const respObj = response.data;
           handleOnClose(id,respObj,true);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       });
    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.workInterval==='') validateErrors['work_interval']='Wypełnij "Odczyt podczas pracy ciągłej"';    
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }   


  return (
      <MainForm title="Konfiguracja" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} errors={errors}>
        <Grid container spacing={3}>  
          <Grid item xs={12} sm={6}>
              <FormTextField required type="number" name="workInterval" value={values.workInterval} label="Częstotliwość odczytu podczas pracy ciągłej (w min.)" maxNumber={999} onChange={handleOnChange} error={errors['work_interval']}/>
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormTextField type="number" name="tempInMax" value={values.tempInMax} label="Krytyczna temperatura maksymalna w kłębie" maxNumber={99} onChange={handleOnChange}/>              
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormTextField type="number" name="tempOutMax" value={values.tempOutMax} label="Krytyczna temperatura maksymalna poza kłębem" maxNumber={99} onChange={handleOnChange}/>
          </Grid> 
          <Grid item xs={12} sm={6}>
              <FormTextField type="number" name="humidityMax" value={values.humidityMax} label="Krytyczna wilgotność maksymalna" maxNumber={99} onChange={handleOnChange}/>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
              <FormTextField type="number" name="co2Max" value={values.co2Max} label="Krytyczny poziom CO2" onChange={handleOnChange}/>
          </Grid>                       */}
        </Grid>              
      </MainForm>
  );
};

export default ConfigForm;
