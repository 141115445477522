import React from 'react';
import MainTable from '../../components/MainTable';
import {Container} from '@material-ui/core';
import ControlFormOne from '../controls/ControlFormOne';
import {API_HIVES_ALERT} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'stat_dt',displayName:'Odczyt',numeric: false,search:false},
    {id:'hive_uid_name',displayName:'Ul',numeric: false,search:true},    
    {id:'p3',displayName:'Temp.pow.',numeric: false,search:false},    
    {id:'p1',displayName:'Temp.w kłębie',numeric: false,search:false},    
    {id:'p2',displayName:'Temp.za kłębem',numeric: false,search:false},        
    {id:'p4',displayName:'Wilgotność',numeric: false,search:false},    
    // {id:'group_name',displayName:'Grupa',numeric: false,search:true},    
    {id:'station',displayName:'Stanowisko',numeric: false,search:true},    
]

const AlertList = () => {
    return ( 
        <Container maxWidth={false}>
            <MainTable EditForm={ControlFormOne} title="Ostrzeżenia" ListFilter={null} apiUrl={API_HIVES_ALERT+'/1'} columns={columns} readOnly={true} sortBy="hive_uid_name" sortDirection="desc" csvName="lista_ostrzezen.csv"/>
        </Container>
     );
}
 
export default AlertList;

