import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Table, TableBody,TableCell,TableContainer,TableHead,TableRow,TableSortLabel,Paper,CircularProgress,Checkbox,Box } from '@material-ui/core';
import { requestError,axiosConfig } from '../utils/Helpers';
import MainErrorMessage from './MainErrorMessage';
import { AppContext } from '../app/AppProvider';

const MainTableSelectable = ({apiUrl,columns,sortBy,sortDirection='asc',prepareList=undefined,selectCallback=undefined,apiKey='',initSelected=[]}) => {
    const { globalState } = useContext(AppContext);      
    const [error,setError]=useState('');              
    const [list,setList] = useState(null);
    const [orderDirection, setOrderDirection] = React.useState(sortDirection);
    const [orderBy, setOrderBy] = React.useState(sortBy);       
    const [selectedIds, setSelectedIds] = useState(initSelected);    

    useEffect(()=> {    
        axios.get(apiUrl,axiosConfig(globalState.token)).then(response => {             
          let data = response.data;
          if (prepareList!==undefined) 
              data=prepareList(data);
          setList(data);
          setSelectedIds(initSelected);
          if (selectCallback!==undefined) selectCallback(initSelected);
        }).catch(error=>{ 
          setList([]);
          setError(requestError(error));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps                    
    },[apiUrl,apiKey]);   
    
    const handleChangeSort = (column) => (event) => {
      const isAsc = orderBy === column && orderDirection === 'asc';
      setOrderDirection(isAsc ? 'desc' : 'asc');
      setOrderBy(column);      
    };
      
    const handleOnSelectRow = (e,id) => {
        let newlistSelected;
        const iIndex = selectedIds.indexOf(id);
        if (iIndex>=0) {
            newlistSelected=[...selectedIds];
            newlistSelected.splice(iIndex,1);
        } else {
            newlistSelected=[...selectedIds,id];
        }       
        setSelectedIds(newlistSelected);            
        if (selectCallback!==undefined) selectCallback(newlistSelected);
    }

    const handleOnSelectAll = e => {
        const newlistSelected=e.target.checked?list.map(row => row.id):[];
        setSelectedIds(newlistSelected); 
        if (selectCallback!==undefined) selectCallback(newlistSelected);
    }
    //SORT
    if (list!==null && list.length) 
    { 
        const sortMuliply = orderDirection==='asc'?-1:1;
        const sortColumn = columns.find(col => col.id===orderBy);      
        list.sort((a,b) =>  { 
            const ap=a[orderBy]!==null?(sortColumn.numeric?a[orderBy]:a[orderBy].toLowerCase()):'';
            const bp=b[orderBy]!==null?(sortColumn.numeric?b[orderBy]:b[orderBy].toLowerCase()):'';
            return sortMuliply * (bp > ap? 1 : -1);
        });
    }

    return ( 
      <Box>        
        {error && <Box><MainErrorMessage errors={{'error':error}}/></Box>}
        {
          list===null ? <CircularProgress />:
          !error &&
          <Box>
              <TableContainer component={Paper}>
              <Table style={{minWidth: 650}} aria-label="table">
                  <TableHead>
                      <TableRow>                          
                          {
                              columns.map((column) => {
                                    return (column.id==='id'?
                                    <TableCell key={column.id} style={{width:'1rem'}}>
                                        <Checkbox
                                            checked={list.length>0 && selectedIds.length === list.length}
                                            color="primary"
                                            indeterminate={selectedIds.length > 0 && selectedIds.length < list.length}
                                            onChange={handleOnSelectAll}
                                        />
                                    </TableCell>:
                                    <TableCell
                                        key={column.id}
                                        align={column.numeric?'right':'left'}
                                        style={column.styleHeader?column.styleHeader:null}
                                        sortDirection={orderBy === column.id ? orderDirection : false}>
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? orderDirection : 'asc'}
                                            onClick={handleChangeSort(column.id)}
                                          >{column.displayName}</TableSortLabel>
                                    </TableCell>)
                              })
                          }                        
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {list.length===0? <TableRow><TableCell colSpan={columns.length}>Brak danych</TableCell></TableRow>:
                          list.map((row) => {
                                    const isSelected = selectedIds.indexOf(row.id) !== -1;
                                    return (
                                        <TableRow hover key={row.id} onClick={(event) => handleOnSelectRow(event, row.id)} selected={isSelected}>                        
                                            {  columns.map((column) => {                                  
                                                return (
                                                    column.id==='id'?
                                                    <TableCell key={column.id}>
                                                        <Checkbox color="primary" checked={isSelected}/>
                                                    </TableCell>: 
                                                    <TableCell key={column.id} align={column.numeric?'right':'left'} style={column.styleCell?column.styleCell:null}>
                                                    {
                                                        column.checkbox?<Checkbox disabled checked={row[column.id]===1}/>:row[column.id]                                      
                                                    }
                                                    </TableCell>)
                                            })
                                            }
                                        </TableRow>
                                    )
                                })}                
                  </TableBody>
              </Table>
              </TableContainer>
          </Box>        
        }
      </Box>        
    );            
}
 
export default MainTableSelectable;