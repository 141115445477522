import React from 'react';
import { CardContent, Card, Container, List } from '@material-ui/core';
import MainHeader from './MainHeader';
import MainListItem from './MainListItem';

const MainList = ({title,items}) => {
    return (
        <Container maxWidth={false}>     
          <MainHeader title={title}/>
          <Card>
              <CardContent>
                <List>
                      {items.map((item) => (
                          <MainListItem
                              navLink={false}
                              href={item.href}
                              key={item.title}
                              title={item.title}
                              icon={item.icon}
                              helperText={item.helperText}
                          />
                      ))}
                </List>
            </CardContent>
          </Card>
        </Container>
      );
}
 
export default MainList;