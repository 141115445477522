import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField, FormCheckbox, FormSelect } from '../../components/FormControls';
import { API_HIVES, API_DR_GROUPS } from '../../utils/Consts';
import { requestError, destructApiWhere, getColor,axiosConfig } from '../../utils/Helpers';
import ColorPicker from '../../utils/ColorPicker' ;
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';

const HiveForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    groupId: -1,
    name: '',
    uid: '',
    chartColor: '',
    hasWeight: 0,
    ifArchive: 0,
  });
  const [listGroups,setListGroups] = useState({});

  useEffect(() => {
    if (id>0) {
        axios.all([axios.get(API_HIVES+'/'+id,axiosConfig(globalState.token)),axios.get(API_DR_GROUPS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2) => {
            console.log('POBRANIE GROUP 1');
            setValues({
              groupId:response1.data.group_id,
              name:response1.data.name,
              uid:response1.data.uid,
              chartColor:response1.data.chart_color,
              hasWeight:response1.data.has_weight,
              ifArchive:response1.data.if_archive
            });

            console.log('POBRANIE GROUP 2');
            setListGroups(response2.data);
        })).catch(error=> {
            setErrors({'error':requestError(error)});
        }); 
    } else {
        axios.get(API_DR_GROUPS,axiosConfig(globalState.token)).then(response => {
            console.log('POBRANIE GROUP 1');
            setListGroups(response.data);
            if (apiWhere!=='') {
                setValues({...values,groupId:destructApiWhere(apiWhere)[0]});
            } else {
                setValues({...values,groupId:0});
            }               
        }).catch(error=> {
            setErrors({'error':requestError(error)});
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);  

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleSetColor = color => {
    setValues({...values,chartColor:color}); 
  }

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {           
       const obj={'group_id':values.groupId,'name':values.name,'uid':values.uid,'chart_color':getColor(values.chartColor),'has_weight':values.hasWeight,'if_archive':values.ifArchive};          
       const promise=id>0?axios.put(API_HIVES+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_HIVES, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const filterParams = destructApiWhere(apiWhere);
           const inFilter = (filterParams[0]===values.groupId) && (filterParams[1]===values.ifArchive);                
           const respObj = response.data;
           handleOnClose(id,respObj,inFilter);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       });
    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.groupId===0) validateErrors['groupId']='Wybierz grupę';    
    if (values.name.length<3) validateErrors['name']='Nazwa musi zawierać co najmniej 3 znaki.';
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }   
 
  return (
      <MainForm title="Ul" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} errors={errors} circuralProgress={values.groupId===-1}> 
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <FormSelect required name="groupId" value={values.groupId} label="Grupa" onChange={handleOnChange} list={listGroups} emptyRowText="Wybierz grupę" error={errors['groupId']} disabled={values.ifArchive===1}/>
          </Grid>
          <Grid item xs={12}>
              <FormTextField required name="name" value={values.name} label="Nazwa" onChange={handleOnChange}  minLength={3} maxLength={30} error={errors['name']}/>
          </Grid>            
          <Grid item xs={12}>
              <FormTextField required name="uid" value={values.uid} label="Unikalny identyfikator ula" minLength={2} maxLength={10} onChange={handleOnChange} disabled={true}/>
          </Grid>  
          <Grid item xs={12}>
              <FormCheckbox name="hasWeight" value={values.hasWeight} label="Zamontowana waga" onChange={handleOnChange} disabled={true}/>
          </Grid>
          <Grid item xs={12}>
              <FormCheckbox name="ifArchive" value={values.ifArchive} label="Archiwalny" onChange={handleOnChange} disabled={true}/>
          </Grid>
          <Grid item xs={12}>    
              <ColorPicker color={values.chartColor} setColor={handleSetColor} label="Kolor na wykresie" helpLabel="przy braku wyboru kolor przydzielany jest automatycznie"/>                
          </Grid>          
        </Grid>              
      </MainForm>
  );
};

export default HiveForm;
