import Moment from 'moment';

export const requestError = error => {
    //console.log(error.response);
    const status=error.response?error.response.status:-1;
    const errs=[];
    if (status!==422) {
        if (status===-1) {
            error.message = error.message.indexOf('Network Error')>-1 ? 'Błąd połączenia z Webservice.':error.message;
            errs.push(error); 
        } else if (error.response.data.error!==undefined)
            errs.push(error.response.data.error); 
    } else {
        for (const value of Object.values(error.response.data.errors)) {
            errs.push(value);
        }
    }
    return error.message +(errs.length>0?":\n":"")+errs.join("\n");
}
export const destructApiWhere = apiWhere => apiWhere.split('/').map(Number);

export const convertDate2String = date => date?Moment(date).format('YYYY-MM-DD'):null;

export const convertString2Time = time => Moment(time, 'HH:mm');

export const convertTime2String = time => Moment(time).format('HH:mm');

export const showStationTitle = station => station.name ? station.name + (station.set_year?' - '+station.set_year:'') + (station.city?' ( '+station.city+' )':'') : null;

export const getColor = color => {
    if (color && color.length>=3) {
        return color;
    } else {
        var letters = '0123456789ABCDEF';
        var ret = '#';
        for (var i = 0; i < 6; i++) {
            ret += letters[Math.floor(Math.random() * 16)];
        }
        return ret;
    }
}  

export const getLocalStorage = (key,defValue='') => localStorage.getItem(key) || defValue;
export const setLocalStorage = (key,value) => localStorage.setItem(key,value);
export const clearLocalStorage = (key) => localStorage.removeItem(key);

export const axiosConfig = (token='') => {
    return {headers: { Authorization: 'Bearer ' + token}}
};

export const convertParamsToArray = paramsData => {
    return Object.assign([], ...paramsData.map(({id, name, unit}) => ({ [id]: {'name':name,'unit':unit }})))
}

export const isNetworkError = error => {
    return error.message.indexOf('Network Error')>-1 || error.response.status===501;
}
