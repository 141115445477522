import React,{useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { Card, CardContent, Box, Grid, makeStyles } from '@material-ui/core';
import { FormSwitch, FormSelect } from '../../components/FormControls';
import {API_GROUPS, API_LIST, LS_GROUP, API_HIVES_UA_COUNT} from '../../utils/Consts';
import {setLocalStorage,getLocalStorage,axiosConfig} from '../../utils/Helpers';
import { AppContext } from '../../app/AppProvider';

const useStyles = makeStyles((theme) => ({
    boxGroup: {
      width:'100%',
      [theme.breakpoints.up('sm')]: {
        width: 300        
      },        
    },
    boxArch: {
      width: 160,
      float:'right',      
      paddingTop: 0,
      [theme.breakpoints.up('sm')]: {
        paddingTop:'0.4rem'  
      },    
    },   
  }));

const HiveFilter = ({handleApiWhere,visible,rowChangedToggle=false}) => {
    const classes = useStyles();
    const { globalState } = useContext(AppContext);    
    const [groupId,setGroupId] = useState(0);    
    const [showArchives,setShowArchives] = useState(0);
    const [showUnassignment,setShowUnassignment] = useState(0);
    const [countUnassignment,setCountUnassignment] = useState(0);    
    const [listGroups,setListGroups] = useState({})
    const setApiWhere =(pGroupId,pShowArchives,pShowUnassignment)=> handleApiWhere(pGroupId+'/'+ (pShowUnassignment===1 ? 2 : pShowArchives));    

    useEffect(()=>{
        axios.get(API_GROUPS + '/' + API_LIST + (showArchives===1 ? '' : '/0'),axiosConfig(globalState.token)).then(response => {               
            setListGroups(response.data);
            if (response.data.length===1) {
                setGroupId(response.data[0].id);
                setApiWhere(response.data[0].id,showArchives,showUnassignment);
            } else {
                const lsGroupID=getLocalStorage(LS_GROUP);
                if (lsGroupID!=='') {
                    setGroupId(lsGroupID);
                    setApiWhere(lsGroupID,showArchives,showUnassignment);
                }                
            }
        }).catch(error=>{ 
            console.log(error.response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps                    
    },[showArchives]);
    
    useEffect(() => {
        axios.get(API_HIVES_UA_COUNT,axiosConfig(globalState.token)).then(response => {               
            setCountUnassignment(response.data);
        }).catch(error=>{ 
            console.log(error.response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[rowChangedToggle]);    
    
    const handleGroupId=(e)=>{
        setGroupId(e.target.value);
        setLocalStorage(LS_GROUP,e.target.value);        
        setApiWhere(e.target.value,showArchives,showUnassignment);
    }

    const handleShowArchives=(e)=>{
        const val=e.target.checked?1:0;
        setShowArchives(val);
        setApiWhere(groupId,val,showUnassignment);
    }   
    
    const handleShowUnassignment=(e)=>{
        const val=e.target.checked?1:0;
        setShowUnassignment(val);
        setApiWhere(groupId,showArchives,val);
    }     
    
    return ( 
        visible && 
        <Card>
            <CardContent>   
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}> 
                    {
                        showUnassignment===0 && 
                        <Box className={classes.boxGroup}>
                            <FormSelect value={groupId} onChange={handleGroupId} label="Grupa" list={listGroups} emptyRowText="Wybierz grupę"/>
                        </Box>
                    }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box className={classes.boxArch} style={{textAlign:"center"}} >
                            <FormSwitch value={showUnassignment} onChange={handleShowUnassignment} label={"Nieprzypisane" + (countUnassignment>0 ? " (" + countUnassignment+ ")" : "")} labelPlacement="Start"/>
                        </Box>                
                        {
                            showUnassignment===0 &&         
                            <Box className={classes.boxArch} >
                                <FormSwitch value={showArchives} onChange={handleShowArchives} label="Archiwalne" labelPlacement="Start"/>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>  
    );
}
 
export default HiveFilter;