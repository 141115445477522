import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import AppTopBar from './AppTopBar';
import AppNavBar from './AppNavBar';
import RoutesSwitch from '../routes/RoutesSwitch';
import { AppContext } from '../app/AppProvider';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    // border:'1px solid red',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 256
    }    
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    // backgroundColor:'#fffff4',
    // minHeight:'calc(100vh - 64px)'
  }
}));

const MainLayout = () => {
  const { globalState } = useContext(AppContext);  
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(false);
  
  return (
    globalState.token.length>20?
    <div className={classes.root}>
      <AppTopBar handleOnNavOpen={() => setNavOpen(true)}/> 
      <AppNavBar handleOnClose={() => setNavOpen(false)} navOpen={navOpen}/>           
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <RoutesSwitch/>
          </div>
        </div>
      </div>
    </div>:<Redirect to="/logowanie"/>
  );
};

export default MainLayout;