import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NotFoundView from '../views/errors/NotFoundView';
import GroupList from '../views/groups/GroupList';
import HiveList from '../views/hives/HiveList';
import MainList from '../components/MainList';
import ConfigForm from '../views/configs/ConfigForm';
import UserForm from '../views/users/UserForm';
import StationList from '../views/stations/StationList';
import ConfigHourList from '../views/config-hours/ConfigHourList';
import DictTaskList from '../views/dict-tasks/DictTaskList';
import Charts from '../views/dashboard/Charts';
import ControlList from '../views/controls/ControlList';
import TaskList from '../views/tasks/TaskList';
import AlertList from '../views/alerts/AlertList';
import ClientAdminList from '../views/clients/ClientAdminList';
import LogoutForm from '../views/login/LogoutForm';
import { routeConfigItems, routeAdminConfigItems, routeHiveItems } from '../routes/RoutesRules';
import {MODE_ADMIN} from '../utils/Consts';

export const HelmetTitle = ({title='React Hives Title'}) => <Helmet><title>{title}</title></Helmet>

const RoutesSwitch = () => {
    const history = useHistory();
    const redirect = url => history.push(url);
    return (
      <Switch>                  
          <Route exact={true} path="/">
            {!MODE_ADMIN ?  
              <>
                <HelmetTitle title="Dashboard"/>
                <Charts/>
              </>
              :
              <>
                <HelmetTitle title="Klienci"/> 
                <ClientAdminList/>
              </>
            }
          </Route>                  
          <Route path="/ostrzezenie">
            <HelmetTitle title="Ostrzeżenia"/>              
            <AlertList/>
          </Route>           
          <Route path="/sterowanie">
            <HelmetTitle title="Sterowanie"/>              
            <ControlList/>
          </Route>            
          <Route path="/zadanie">
            <HelmetTitle title="Zadania"/>              
            <TaskList/>
          </Route>             
          <Route path="/stanowisko">
            <HelmetTitle title="Stanowiska"/>              
            <StationList/>
          </Route>   
          <Route path="/wyloguj">
            <LogoutForm/>
          </Route>                    

          <Route exact path="/ul">
            <HelmetTitle title="Grupy i ule"/>              
            <MainList title="Grupy i ule" items={routeHiveItems}/>
          </Route>   
              <Route path="/ul/grupa">
                <HelmetTitle title="Grupy"/>
                <GroupList/>
              </Route>
              <Route path="/ul/lista">
                <HelmetTitle title="Ule"/>              
                <HiveList/>
              </Route>

          <Route exact path="/ustawienia">
            <HelmetTitle title="Ustawienia"/>              
            <MainList title="Ustawienia" items={MODE_ADMIN ? routeAdminConfigItems: routeConfigItems}/>
          </Route>                    
                <Route path="/ustawienia/konfiguracja">
                  <HelmetTitle title="Konfiguracja"/>              
                  <ConfigForm id={1} handleOnClose={()=>redirect('/ustawienia')}/>
                </Route>  
                <Route path="/ustawienia/godzina">
                  <HelmetTitle title="Godziny odczytu"/>              
                  <ConfigHourList/>
                </Route> 
                <Route path="/ustawienia/rodzaj-zadania">
                  <HelmetTitle title="Rodzaje zadań"/>              
                  <DictTaskList/>
                </Route>                                   
                <Route path="/ustawienia/uzytkownik">
                  <HelmetTitle title="Użytkownik"/>              
                  <UserForm handleOnClose={()=>redirect('/ustawienia')}/>
                </Route> 
                  

          <Route path="*">
            <HelmetTitle/>              
            <NotFoundView error="Not found"/>
          </Route>          
      </Switch>
    );
}

export default RoutesSwitch;