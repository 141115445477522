import React, {useState,useEffect, useContext} from 'react';
import axios from 'axios';
import { Grid, Box, Typography, makeStyles  } from '@material-ui/core';
import { FormTextField, FormSelect } from '../../components/FormControls';
import { requestError, showStationTitle,axiosConfig, convertParamsToArray } from '../../utils/Helpers';
import {API_HIVES,API_DR_DICT_CONTROLS,API_CONTROLS,API_DR_DICT_PARAMS} from '../../utils/Consts';
import MainForm from '../../components/MainForm';
import { AppContext } from '../../app/AppProvider';

const useStyles = makeStyles((theme) => ({
    alertText: {
        color:theme.palette.error.main,
        fontWeight:'bold'
    }    
}));

const TypographyLine =({classCond,title,value}) => {
    const classes = useStyles();
    return <Typography component="h3" className={classCond?classes.alertText:null}>{title}: {value}</Typography>
}

const ControlFormOne = ({handleOnClose,id=0,gridMode=false}) => {
    const { globalState } = useContext(AppContext);    
  
    const [errors,setErrors]=useState({});
    const [arrDictParams,setArrDictParams] = useState([]);
    const [values, setValues] = useState({
        dictControlId: 0,
        parameterValue: '',
        parameterRequired: false,
        parameterDescription: '',
        name: '',
        groupId: '',
        groupName: '',
        groupReadOnly: 0,
        station: '',
        p1:'',
        p2:'',
        p3:'',
        p4:'',
        p5:'',
        p6:'',
        p7:'',                
        alert:0
    });
    const [listDictControls,setListDictControls] = useState([]);    
            
    useEffect(() => {
        axios.all([axios.get(API_HIVES+'/'+id+'/full',axiosConfig(globalState.token)),
        axios.get(API_DR_DICT_CONTROLS,axiosConfig(globalState.token)),
        axios.get(API_DR_DICT_PARAMS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2,response3) => {
            const dictControls = response1.data.if_read_only === 0 ?  response2.data : response2.data.filter(dictControl => dictControl.if_only_weight===1);

            setListDictControls(dictControls);
            setArrDictParams(convertParamsToArray(response3.data));

            let dictControlId=0;
            let parameterRequired=false;
            if (response1.data.alert>0 && response1.data.if_read_only===0) {
                const dictControl=dictControls.find(dictControl => dictControl.alert_no===response1.data.alert);
                if (dictControl!==undefined) {
                    dictControlId=dictControl.id;
                    parameterRequired=dictControl.if_parameter===1;
                }
            } else {
                if (dictControls.length===1)
                    dictControlId=dictControls[0].id;
            }

            const stationResponse={name:response1.data.station_name,city:response1.data.station_city,set_year:response1.data.station_set_year};
            setValues({...values,
                dictControlId:dictControlId,
                parameterRequired:parameterRequired,
                parameterDescription:response1.data.station_param_desc,
                name:response1.data.uid_name,
                groupId:response1.data.group_id,
                groupName:response1.data.group_name,
                groupReadOnly:response1.data.if_read_only,
                p1:response1.data.p1,
                p2:response1.data.p2,
                p3:response1.data.p3,
                p4:response1.data.p4,
                p5:response1.data.p5,
                p6:response1.data.p6,
                p7:response1.data.p7,                                
                alert:response1.data.alert,
                station:showStationTitle(stationResponse)});
        })).catch(error=> {
            setErrors({'error':requestError(error)});
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps                          
    },[]);

    const handleOnChange = e => {
        setValues({...values,[e.target.name]:e.target.value});
    };   
        
    const handleOnChangeDictControlId = e => {
        const dictControlId=e.target.value;
        const parameterRequired =dictControlId>0?listDictControls.find(dictControl => dictControl.id===dictControlId).if_parameter===1:false;
        setValues({...values,[e.target.name]:dictControlId,parameterRequired:parameterRequired});
    };     

    const handleOnSubmit=e=> {
        e.preventDefault();
        if (validateForm()) {
            const obj={'dict_control_id':values.dictControlId,'group_id':values.groupId,'hives_id':[id],'param_value':values.parameterValue};            
            const promise=axios.post(API_CONTROLS, obj,axiosConfig(globalState.token));
            promise.then(response => { 
                if (gridMode) {              
                    handleOnClose('Dodanie sterowania zakończono sukcesem.');
                } else {
                    handleOnClose(-1,undefined,true,'Dodanie sterowania zakończono sukcesem.');
                }
            })
            .catch(error=>{ 
                setErrors({'error':requestError(error)});
            });            
        } 
    }

    const validateForm=()=>{
        const validateErrors={};
        if (values.dictControlId===0) validateErrors['dictControlId']='Wybierz rodzaj sterowania.';
        if (values.dictControlId>0 && values.parameterRequired && values.parameterValue.length<1) validateErrors['parameterValue']=values.parameterDescription;
        if (Object.keys(validateErrors).length>0) {
            setErrors(validateErrors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    }    

    const handleOnCancel=()=>{
        if (gridMode) { 
            handleOnClose('');
        } else {
            handleOnClose();
        }
    }

    return ( 
        <MainForm title="Sterowanie" id={id} onSubmit={handleOnSubmit} onCancel={handleOnCancel} errors={errors}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h5">{values.name}</Typography>
                    <Typography component="h3">Grupa: {values.groupName}</Typography>
                    <Typography component="h3">Stanowisko: {values.station}</Typography>
                    {arrDictParams.length>0 &&
                        <Box pt={2}>
                            <TypographyLine classCond={values.alert===1 || values.alert===3} title={arrDictParams[11].name} value={values.p1+arrDictParams[11].unit}/>
                            <TypographyLine classCond={values.alert===2} title={arrDictParams[12].name} value={values.p2+arrDictParams[12].unit}/>
                            <TypographyLine classCond={values.alert===4} title={arrDictParams[14].name} value={values.p4+arrDictParams[14].unit}/>                            
                            {values.p7 && <TypographyLine classCond={false} title={arrDictParams[17].name} value={values.p7+arrDictParams[17].unit}/>}
                        </Box>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormSelect required name="dictControlId" 
                        value={values.dictControlId} 
                        label="Sterowanie" 
                        onChange={handleOnChangeDictControlId} 
                        list={listDictControls} 
                        error={errors['dictControlId']}/>
                </Grid>            
                <Grid item xs={12}>
                    {
                        values.parameterRequired &&
                        <Box className="boxInputBigText">
                            <FormTextField name="parameterValue" type="number" value={values.parameterValue} onChange={handleOnChange}
                             minNumber={-99} maxNumber={99} label={values.parameterDescription} error={errors['parameterValue']}/>
                        </Box>
                    }
                </Grid>
            </Grid>              
      </MainForm>
    );
}
 
export default ControlFormOne;