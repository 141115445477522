import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import EmojiNatureIcon from '@material-ui/icons/EmojiNature';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PlaceIcon from '@material-ui/icons/Place';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PeopleIcon from '@material-ui/icons/People';

export const routeAppItems = [
    { href: '/', icon: DashboardIcon, title: 'Dashboard' },
    { href: '/sterowanie',  icon: PlayArrowIcon, title: 'Sterowanie' },
    { href: '/zadanie',  icon: PlaylistAddCheckIcon, title: 'Zadania' },
    { href: '/stanowisko',  icon: PlaceIcon, title: 'Stanowiska' },
    { href: '/ul',  icon: EmojiNatureIcon, title: 'Ule' },
    { href: '/ustawienia',  icon: SettingsIcon, title: 'Ustawienia' },
    { href: '/wyloguj',  icon: PowerSettingsNewIcon, title: 'Wyloguj' },
];


export const routeHiveItems = [
    { href: '/ul/grupa', icon: GroupWorkIcon, title: 'Grupy', helperText:'Lista grup do których przynależeć mogą ule' },
    { href: '/ul/lista',  icon: EmojiNatureIcon, title: 'Lista uli', helperText:'Konfiguracja uli, wybór grupy, nazwy, zamontowanie wagi'},
];

export const routeConfigItems = [
    { href: '/ustawienia/konfiguracja', icon: SettingsIcon, title: 'Konfiguracja', helperText:'Ogólne ustawienia aplikacji' },
    { href: '/ustawienia/godzina',  icon: ScheduleIcon, title: 'Godziny odczytu', helperText:'Godziny w których parametry mają być odczytywane z uli'},
    { href: '/ustawienia/rodzaj-zadania',  icon: AssignmentIcon, title: 'Rodzaje zadań', helperText:'Słownik rodzajów zadań'},
    { href: '/ustawienia/uzytkownik',  icon: PersonIcon, title: 'Użytkownik', helperText:'Ustawienia użytkownika systemu, wybór trybu ciemnego'},    
];

// Admin routes
export const routeAdminAppItems = [
    { href: '/',  icon: PeopleIcon, title: 'Klienci' },        
    { href: '/ul',  icon: EmojiNatureIcon, title: 'Ule' },    
    { href: '/ustawienia',  icon: SettingsIcon, title: 'Ustawienia' },
    { href: '/wyloguj',  icon: PowerSettingsNewIcon, title: 'Wyloguj' },
];

export const routeAdminConfigItems = [
    { href: '/ustawienia/uzytkownik',  icon: PersonIcon, title: 'Użytkownik', helperText:'Ustawienia użytkownika systemu, wybór trybu ciemnego'},    
];