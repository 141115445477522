import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import { IconButton, makeStyles } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const useStyles = makeStyles((theme) => ({
    btnExport: {
      display:'block',
      [theme.breakpoints.down('sm')]: {
        display:'none'
      },     
    }
  }));

const ExportCsv = ({fileName,datas,columns}) => {
    const classes = useStyles();      
    return (
        <CsvDownloader
        className={classes.btnExport}
        filename={fileName}
        separator=","
        wrapColumnChar='"'
        columns={columns}
        datas={datas}>
            <IconButton><SaveAltIcon/></IconButton>
        </CsvDownloader> 
    );
}

export default ExportCsv;