import React from 'react';
import MainTable from '../../components/MainTable';
import {Container} from '@material-ui/core';
import ControlForm from './ControlForm';
import ControlFilter from './ControlFilter';
import {API_CONTROLS,API_LIST} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'dt_insert',displayName:'Dodano',numeric: false,search:false}, 
    {id:'hive_uid_name',displayName:'Ul',numeric: false,search:true}, 
    {id:'dict_control_name',displayName:'Sterowanie',numeric: false,search:false}, 
    {id:'param_value',displayName:'Parametr',numeric: true,search:true},    
    {id:'sync_lvl_name',displayName:'Etap',numeric: false,search:false}, 
    {id:'error_message',displayName:'Błąd',numeric: false,search:false,checkbox:false}
]

const ControlList = () => {
    return ( 
        <Container maxWidth={false} className="tableNoFirstColumn">
            <MainTable EditForm={ControlForm} title="Sterowanie" ListFilter={ControlFilter} apiUrl={API_CONTROLS+'/'+API_LIST+'/'} apiUrlWhere="0/0" columns={columns} sortBy="dt_insert" sortDirection="desc" csvName="lista_sterowania.csv"/>
        </Container>
     );
}
 
export default ControlList;

