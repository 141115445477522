import React, { useState } from 'react';
import {makeStyles, Container, Card, CardContent, CardHeader, Divider, Button, Box,CircularProgress } from '@material-ui/core';
import MainErrorMessage from './MainErrorMessage';
import ConfirmDialog from '../utils/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    buttonAction: {
      width: 120,
    },
  }));

const MainForm = ({title,children,id,onSubmit,onCancel,onDelete,errors,circuralProgress=false}) => {
    const [confirmDelete,setConfirmDelete]=useState(false);    
    const classes = useStyles();

    const handleOnDelete=()=>{
        setConfirmDelete(true);
    }

    const handleDeleteConfirm = ok => {
        setConfirmDelete(false);
        ok && onDelete();
    }

    return (  
    <Container maxWidth="lg">
        {confirmDelete && <ConfirmDialog onClose={handleDeleteConfirm}/>}
        <MainErrorMessage errors={errors}/>
        <form onSubmit={onSubmit}>
        <Card>
            <CardHeader title={title}/>
            <Divider />
            {
                circuralProgress?<CircularProgress style={{margin:'1rem'}}/>:
                <>
                    <CardContent>
                        {children}      
                    </CardContent>
                    <Divider />
                    <Box display="flex" p={2}>
                        {onDelete && id>0 && <Button size="large" onClick={handleOnDelete}>Usuń</Button>}
                        <Box flexGrow={1} />
                        <Button className={classes.buttonAction} size="large" variant="contained" onClick={onCancel}>Anuluj</Button>
                        {onSubmit && <Button className={classes.buttonAction} style={{marginLeft:'0.5rem'}} color="primary" variant="contained" type="submit">Zapisz</Button>}
                    </Box>
                </>                
            }
        </Card>
        </form>
    </Container>        
    );
}
 
export default MainForm;