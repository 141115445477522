import React, { useEffect, useContext } from 'react';
import { makeStyles, Box, Divider, Typography, Drawer, Hidden, List } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import MainListItem from '../components/MainListItem';
import { routeAppItems, routeAdminAppItems } from '../routes/RoutesRules';
import { AppContext } from '../app/AppProvider';
import {MODE_ADMIN} from '../utils/Consts';

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    }
}));

const AppNavBar = ({ handleOnClose, navOpen}) => {
   const { globalState } = useContext(AppContext);    
    const classes = useStyles();    
    const location = useLocation();
    
    useEffect(() => {
        if (navOpen && handleOnClose) {
            handleOnClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column" >
          <Box alignItems="center" display="flex" flexDirection="column" p={2}> {/* bgcolor="background.dark"*/ }
            <Typography  color="textPrimary" variant="h5" align="center">
              {globalState.user}
            </Typography>
          </Box>          
          <Divider />
          <Box p={2}>
                <List>
                    {(MODE_ADMIN ? routeAdminAppItems: routeAppItems).map((item) => (
                        <MainListItem
                            navLink={true}
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
          </Box>
        </Box>
    );

    return ( 
        <>
        <Hidden lgUp>
          <Drawer
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={handleOnClose}
            open={navOpen}
            variant="temporary">
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent">
            {content}
          </Drawer>
        </Hidden>
      </>
    );
}
 
export default AppNavBar;