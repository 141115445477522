import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField, FormCheckbox, FormSelect } from '../../components/FormControls';
import { API_STATIONS, API_DR_GROUPS, API_CONFIGS, API_DR_DICT_CONTROLS_PARAM_TYPE } from '../../utils/Consts';
import { requestError, destructApiWhere,axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import moment from 'moment';
import { AppContext } from '../../app/AppProvider';

const StationForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);   
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    groupId: 0,
    groupReadOnly: 0,
    name: '',
    city: '',
    setYear: '',
    ifModeContinue: 0,
    paramType: 1,
    ifArchive: 0,
    workInterval: 0
  });
  const [listGroups,setListGroups] = useState({});  
  const [listDictParamType,setListDictParamType] = useState({});

  useEffect(() => {
    if (id>0) {
        axios.all([axios.get(API_STATIONS+'/'+id,axiosConfig(globalState.token)),
                  axios.get(API_DR_GROUPS,axiosConfig(globalState.token)),
                  axios.get(API_CONFIGS + '/1',axiosConfig(globalState.token)),
                  axios.get(API_DR_DICT_CONTROLS_PARAM_TYPE,axiosConfig(globalState.token))]).then(axios.spread((response1,response2,response3,response4) => {
            setValues({
              groupId:response1.data.group_id,
              groupReadOnly:response1.data.if_read_only,
              name:response1.data.name,
              city:response1.data.city,
              setYear:response1.data.set_year,
              ifModeContinue:response1.data.if_mode_continue,
              paramType:response1.data.param_type,
              ifArchive:response1.data.if_archive,
              workInterval:response3.data.work_interval
            });
            setListGroups(response2.data);
            setListDictParamType(response4.data);
            console.log(response2.data);
            console.log(response1.data);
        })).catch(error=> {
            setErrors({'error':requestError(error)});
        }); 
    } else {      
        setValues({...values,setYear:moment().year()});
        axios.all([axios.get(API_DR_GROUPS,axiosConfig(globalState.token)),
                  axios.get(API_CONFIGS + '/1',axiosConfig(globalState.token)),
                  axios.get(API_DR_DICT_CONTROLS_PARAM_TYPE,axiosConfig(globalState.token))]).then(axios.spread((response1,response2,response3) => {
            setValues({...values, workInterval:response2.data.work_interval});                    
            setListGroups(response1.data);
            setListDictParamType(response3.data);
        })).catch(error=> {
            setErrors({'error':requestError(error)});
        }); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]); 

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnChangeGroupId = e => {
    const groupId = e.target.value;
    setValues({...values,[e.target.name]:groupId,groupReadOnly:getGroupReadOnly(groupId)});
  };  

  const getGroupReadOnly = groupId => {
    return listGroups && listGroups.find(group => group.id===groupId).if_read_only;
  }  

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {
       const ifModeContinue=values.ifArchive===1?0:values.ifModeContinue;
       const obj={'group_id':values.groupId,'name':values.name,'city':values.city,'set_year':values.setYear,'if_mode_continue':ifModeContinue,'param_type':values.groupReadOnly?1:values.paramType,'if_archive':values.ifArchive};                 
       const promise=id>0?axios.put(API_STATIONS+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_STATIONS, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const filterParams = destructApiWhere(apiWhere);
           const inFilter = (filterParams[0]===values.ifArchive);                
           const respObj = response.data;
           handleOnClose(id,respObj,inFilter);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       }); 

    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.groupId===0) validateErrors['group_id']='Wybierz grupę';    
    if (values.name.length<3) validateErrors['name']='Nazwa musi zawierać co najmniej 3 znaki.';
    if (values.setYear===0) validateErrors['set_year']='Wypełnij rok';    
    if (!values.groupReadOnly && values.paramType===0) validateErrors['param_type']='Wybierz sposób sterowania temperaturą';    
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }   

  const handleOnDelete= () => {
    axios.delete(API_STATIONS+'/'+id,axiosConfig(globalState.token)).then(response => {
      handleOnClose(id,{},false);
    }).catch(error=>{ 
        setErrors({'error':requestError(error)});
    });
  }     

  console.log('values.groupReadOnly='+values.groupReadOnly);
  // circuralProgress={listGroups.length===0 || (id>0 && values.groupId===0)}
  return (
      <MainForm title="Stanowisko" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} onDelete={handleOnDelete} errors={errors} circuralProgress={id>0 && values.groupId===0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <FormSelect required name="groupId" value={values.groupId} label="Grupa" onChange={handleOnChangeGroupId} list={listGroups} emptyRowText="Wybierz grupę" error={errors['group_id']}/>
          </Grid>
          <Grid item xs={12}>
              <FormTextField required name="name" value={values.name} label="Nazwa" onChange={handleOnChange} minLength={3} maxLength={30} error={errors['name']}/>
          </Grid>            
          <Grid item xs={12}>
              <FormTextField name="city" value={values.city} label="Miejscowość" minLength={3} maxLength={50} onChange={handleOnChange}/>
          </Grid>  
          <Grid item xs={12}>
              <FormTextField required type="number" name="setYear" value={values.setYear} label="Rok" minNumber={2021} maxNumber={2100} onChange={handleOnChange} error={errors['set_year']}/>
          </Grid>
          {
            !values.groupReadOnly &&
            <Grid item xs={12}>
                <FormSelect required name="paramType" value={values.paramType} label="Sposób sterowania temperaturą" onChange={handleOnChange} list={listDictParamType} emptyRowText="Wybierz sposób sterowania temperaturą" error={errors['param_type']}/>
            </Grid>          
          }
          {
            values.ifArchive===0 &&
            <Grid item xs={12}>
               <FormCheckbox  name="ifModeContinue" value={values.ifModeContinue} label={"Odczyt ciągły (co " + values.workInterval + " min.)"} onChange={handleOnChange} />
            </Grid>
          }           
          <Grid item xs={12}>
              <FormCheckbox  name="ifArchive" value={values.ifArchive} label="Archiwalne" onChange={handleOnChange} />
          </Grid>                   
        </Grid>              
      </MainForm>
  );
};

export default StationForm;
