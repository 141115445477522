import React, { useState } from 'react';
import { SketchPicker  } from 'react-color';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box,FormHelperText, Typography    } from '@material-ui/core';


export const useStyles = makeStyles((theme) => ({
    popover: {
        position: 'absolute',
        zIndex: '2',
    },    
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },   
    button: {
        height:'2rem',
        width:'2rem',
        minWidth:'2rem'
    } 
}));

const ColorPicker = ({color,setColor,label,helpLabel,disabled=false}) => {
    const classes=useStyles();
    const [display,setDisplay] = useState(false);
    return (      
        <Box>
            { !disabled && 
              <div>
              <Button type="button" style={{border:'1px solid black',backgroundColor:color}} className={classes.button} onClick={ () => setDisplay(!display) }>&nbsp;</Button>
              <Typography display="inline" style={{paddingLeft:'1rem'}}>{label}</Typography>
              {helpLabel && <FormHelperText>{helpLabel}</FormHelperText>}
              </div>
            }
            { 
                display  && 
                    <div className={ classes.popover }>
                    <div className={ classes.cover } onClick={ () => setDisplay(false) }/>
                    <SketchPicker color={color?color:'#000'} onChangeComplete={color=>setColor(color.hex)} />
                </div> 
            }
      </Box>);
}
 
export default ColorPicker;