import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const FadeSnackbar = ({onClose,message,typeMessage="error",autoHideDuration=null}) => {
  //typeMessage: error,warning,info,success
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const arr = message.split('~~');
  const messageShow = arr[0];
  const link = arr.length>1?<a style={{marginLeft:'0.4rem',color:'#fff'}} href={arr[1]}>Kliknij</a>:null;
  return (
    <div>
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center', }}
        open={open}
        {...(autoHideDuration!==null && {autoHideDuration:autoHideDuration})}
        onClose={handleClose}>
            <Alert onClose={handleClose} severity={typeMessage} variant="filled">
              {messageShow}
              {link}
            </Alert>
      </Snackbar>  
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message={<span id="message-id">{props.errorMessage}</span>}/> */}
    </div>
  );
}

export default FadeSnackbar;
