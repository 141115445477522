import React from 'react';
import {
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
  TextField, 
  Switch,
  FormControl,
  FormControlLabel,
  Checkbox,
  Tooltip
} from '@material-ui/core';


export const FormTextField = ({onChange,name,label,value,error=undefined,required=false,type="text",minLength=0,maxLength=191,minNumber=0,maxNumber=999,disabled=false}) => {
    const inputProps = type==="number" ?  { min: minNumber, max: maxNumber} : { minLength: minLength, maxLength: maxLength}
    return (
        <TextField fullWidth type={type} required={required} name={name} label={label} value={value}
            onChange={onChange} error={error!==undefined} helperText={error} variant="outlined" 
            inputProps={inputProps} disabled={disabled ? "disabled" : ""}/>
    );
}    


export const FormCheckbox = ({onChange,name,label,value,disabled=false}) => {
    return (
        <FormControl>
            <FormControlLabel control={<Checkbox name={name} checked={value===1} onChange={onChange} disabled={disabled ? "disabled" : ""}/>} label={label}/>
        </FormControl>
    );
}

export const FormSwitch = ({onChange,name,label,value,labelPlacement="end",tooltip=''}) => {
    const control=<FormControlLabel control={<Switch name={name} checked={value===1} onChange={onChange}/>} label={label} labelPlacement={labelPlacement}/>;
    return (
        <FormControl>
            {tooltip!==''?<Tooltip title={tooltip} arrow>{control}</Tooltip>:control}
        </FormControl>
    );
}


export const FormSelect = ({onChange,name,label,value,error=undefined,required=false,list,field="name",emptyRowText='',disabled=false}) => {  
    if (list.length<=0) return null;
    return (
        <FormControl fullWidth required={required} error={error!==undefined} variant="outlined" disabled={disabled ? "disabled" : ""}>
            <InputLabel>{label}</InputLabel>
            <Select name={name} value={value} onChange={onChange} label={label}> 
                {emptyRowText!=='' &&  <MenuItem value={0}><em>{emptyRowText}</em></MenuItem>}
                { list.length && list.map(row => <MenuItem key={row.id} value={row.id}>{row[field]}</MenuItem>) }
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}
