import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Table, TableBody,TableCell,TableContainer,TableHead,TableRow,TableFooter,TablePagination,TableSortLabel,Paper,CircularProgress,
        TextField, IconButton, Checkbox, Box, Button, Card, CardContent, InputAdornment, SvgIcon, makeStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ExportCsv from '../utils/ExportCsv';
import { requestError,axiosConfig,setLocalStorage,getLocalStorage } from '../utils/Helpers';
import MainHeader from './MainHeader';
import MainErrorMessage from './MainErrorMessage';
import FadeSnackbar from '../utils/FadeSnackbar';
import { AppContext } from '../app/AppProvider';
import { LS_ROWS_PER_PAGE } from '../utils/Consts';

const useStyles = makeStyles((theme) => ({
  tfSearch: {
    width:'100%',
    [theme.breakpoints.up('sm')]: {
      width:300      
    },        
  },
  btnAdd: {
    float:'right',
    marginTop:10,
    width:120,
    marginBottom:10,
    [theme.breakpoints.up('sm')]: {
      marginBottom:0
    },    
  }
}));

const MainTable = ({EditForm,ListFilter,title,apiUrl,apiUrlWhere='',apiUrlWhereFromParent=false,apiKey='',columns,csvName='export.csv',sortBy,sortDirection='asc',readOnly=false,callbackID=undefined,tableMinWidth=650}) => {
    const classes = useStyles();  
    const { globalState } = useContext(AppContext);  
    const [id,setId]= useState(-1); 
    const [error,setError]=useState('');           
    const [snackBarMessage,setSnackBarMessage]=useState('');    
    const [apiWhere,setApiWhere] = useState(apiUrlWhere);
    const [list,setList] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowChangedToggle, setRowChangedToggle] = React.useState(false);
    const [orderDirection, setOrderDirection] = React.useState(sortDirection);
    const [orderBy, setOrderBy] = React.useState(sortBy);   
    const [search, setSearch] = useState('');    

    useEffect(()=>
    {    
        const url=apiUrl + (apiUrlWhereFromParent?apiUrlWhere:apiWhere);
        axios.get(url,axiosConfig(globalState.token)).then(response => {             
          setList(response.data);
          setPage(0);
          const lsRowsPerPage=getLocalStorage(LS_ROWS_PER_PAGE);
          if (lsRowsPerPage!=='') {
            setRowsPerPage(parseInt(lsRowsPerPage))
          }
        }).catch(error=>{ 
          setList([]);
          setError(requestError(error));
        }); 
        // eslint-disable-next-line react-hooks/exhaustive-deps                   
    },[apiUrlWhereFromParent?apiUrlWhere:apiWhere,apiKey]);

    
    useEffect(()=>
    {    
        if (callbackID !== undefined) {
            callbackID(id);          
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    },[id]);      

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      const rowsPerPage = parseInt(event.target.value, 10); 
      setLocalStorage(LS_ROWS_PER_PAGE,rowsPerPage);
      setRowsPerPage(rowsPerPage);
      setPage(0);
    };   
    
    const handleChangeSort = (column) => (event) => {
      const isAsc = orderBy === column && orderDirection === 'asc';
      setOrderDirection(isAsc ? 'desc' : 'asc');
      setOrderBy(column);      
    };
      
    //SORT, SEARCH
    let showList = {};
    let emptyRowsCount = 0;    
    if (id<0) {
        if (list!==null && list.length) 
        { 
            // SEARCH
            if (search!=='') {        
                const columnsToSearched = columns.filter(column => column.search===true).map(column => { return column.id;});          
                console.log(columnsToSearched);
                showList = list.filter(row => {               
                    let bReturn = false;              
                    for(var columnId of columnsToSearched){
                      bReturn = row[columnId] && row[columnId].toString().toLowerCase().includes(search.toLowerCase()); 
                      if (bReturn) break;
                    }          
                    return bReturn;
                });          
            } else {
                showList = list.slice();
            }

            //SORT 
            const sortMuliply = orderDirection==='asc'?-1:1;
            const sortColumn = columns.find(col => col.id===orderBy);      
            showList.sort((a,b) =>  { 
                const ap=a[orderBy]!==null?(sortColumn.numeric?a[orderBy]:a[orderBy].toString().toLowerCase()):'';
                const bp=b[orderBy]!==null?(sortColumn.numeric?b[orderBy]:b[orderBy].toString().toLowerCase()):'';
                return sortMuliply * (bp > ap? 1 : -1);
            });
            emptyRowsCount = rowsPerPage - Math.min(rowsPerPage, showList.length - page * rowsPerPage);
        }
    }

    const handleEditFormClose=(idEditForm=-1,respObj=undefined,inFilter=true,snackBarToast='')=> {        
        if (idEditForm>=0) {
            let newList;
            if (idEditForm>0) {
                newList = inFilter?list.map(row => (row.id===idEditForm?respObj:row)):list.filter(row => row.id!==idEditForm);                    
            } else {                                              
                if (inFilter) {
                    if (Array.isArray(respObj)) {
                      newList = [...list,...respObj];
                    } else {
                      newList = [...list,respObj];
                    }
                } else {
                  newList = [...list];
                }
            }
            setList(newList);   
            setRowChangedToggle(oldRowChangedToggle=>!oldRowChangedToggle);
            if (idEditForm>0 && !inFilter) setPage(0);        
        }
        setId(-1);
        if (snackBarToast!=='') setSnackBarMessage(snackBarToast);        
    }

    const handleOnCloseSnackBar=()=> {
      setSnackBarMessage('');
  }      

    return ( 
    <Box>                
        {snackBarMessage && <FadeSnackbar onClose={handleOnCloseSnackBar} message={snackBarMessage} typeMessage="success" autoHideDuration={3000}/>} 
        <MainHeader title={title} visible={id<0}/>
        {
          error?<MainErrorMessage errors={{'error':error}}/>:
          ListFilter && <Box mb={3}><ListFilter handleApiWhere={setApiWhere} visible={id<0} rowChangedToggle={rowChangedToggle}/></Box>
        } 

        {id>=0?<EditForm id={id} handleOnClose={handleEditFormClose} apiWhere={apiUrlWhereFromParent?apiUrlWhere:apiWhere}/>:      
          list===null ? <CircularProgress />:
          !error && 
          <Box>        
              <Box mb={3}>
                <Card>
                    <CardContent>                
                    <TextField className={classes.tfSearch} variant="outlined" placeholder="Szukaj" value={search} onChange={e=>{setSearch(e.target.value);setPage(0);}} 
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start" onClick={()=>setSearch('')} style={{cursor:search!==''?'pointer':'default'}}>
                                            <SvgIcon color="action">
                                            {search!==''?<CloseIcon/>:<Search/>}
                                            </SvgIcon>
                                        </InputAdornment>
                                        )
                                    }}/>                  
                    {!readOnly && <Button className={classes.btnAdd} onClick={()=>setId(0)} variant="contained" color="primary">Dodaj</Button>}
                    </CardContent>
                </Card>          
              </Box> 

              <TableContainer component={Paper}>
              <Table style={{minWidth: tableMinWidth}} aria-label="table" className="mainTable">
                  <TableHead>
                      <TableRow>                          
                          {
                              columns.map((column) => {
                                    return (column.id==='id'?<TableCell key={column.id} style={{width:'1rem'}}>&nbsp;</TableCell>:
                                    <TableCell
                                        key={column.id}
                                        align={column.numeric?'right':'left'}
                                        style={column.styleHeader?column.styleHeader:null}
                                        sortDirection={orderBy === column.id ? orderDirection : false}>
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? orderDirection : 'asc'}
                                            onClick={handleChangeSort(column.id)}
                                          >{column.displayName}</TableSortLabel>
                                    </TableCell>)
                              })
                          }                        
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {list.length===0? <TableRow><TableCell colSpan={columns.length}>Brak danych</TableCell></TableRow>:
                          (rowsPerPage > 0? showList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage): showList).map((row) => (
                          <TableRow hover={false} key={row.id}>                        
                            {  columns.map((column) => { return (
                                    column.id==='id'?
                                    <TableCell key={column.id}><IconButton onClick={()=>setId(row[column.id])} size="small"><EditIcon/></IconButton></TableCell>: 
                                    <TableCell key={column.id} align={column.numeric?'right':'left'} style={column.styleCell?column.styleCell:null}>
                                      {
                                        column.checkbox?<Checkbox checked={row[column.id]===1}/>:row[column.id]                                      
                                      }
                                    </TableCell>)
                              })
                            }
                          </TableRow>
                      ))}
                      {emptyRowsCount > 0 && (<TableRow style={{ height: 63 * emptyRowsCount }}><TableCell colSpan={columns.length}/></TableRow>)}                
                  </TableBody>
                  { list.length>0 && 
                    <TableFooter>
                        <TableRow>
                            <ExportCsv datas={showList} columns={columns} fileName={csvName}/>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: '-', value: -1 }]}
                            colSpan={columns.length}
                            count={showList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            labelRowsPerPage="Na stronie"
                            labelDisplayedRows={({ from, to, count }) => <span>{from}-{to} z {count}</span>}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter> 
                  }               
              </Table>
              </TableContainer>
          </Box>        
        }
    </Box>        
    );            
}
 
export default MainTable;