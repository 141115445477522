import React,{ useState } from 'react';
import { Card,CardContent,Box } from '@material-ui/core';
import { FormSwitch } from '../../components/FormControls';

const StationFilter = ({handleApiWhere,visible}) => {
    const [showArchives,setShowArchives] = useState(0);
    const setApiWhere =(pShowArchives)=> handleApiWhere(pShowArchives.toString());
    
    const handleShowArchives=(e)=>{
        const val=e.target.checked?1:0;
        setShowArchives(val);
        setApiWhere(val);
    }    
    
    return ( 
        visible && 
        <Card>
            <CardContent style={{padding:0}}>   
                <Box width={160} style={{float:'right'}} >
                    <FormSwitch value={showArchives} onChange={handleShowArchives} label="Archiwalne" labelPlacement="Start"/>
                </Box>
            </CardContent>
        </Card>  
    );
}
 
export default StationFilter;